import { Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Header from "./Header";
import NewHeader from "./NewHeader";
import NewMobileHeader from "./NewMobileHeader";
import Footer from "./Footer";
import TabletHeader from "./TabletHeader";
import MobileHeader from "./MobileHeader";

import DropDown from "./DropDown";

import MobileFooter from "./MobileFooter";

export default function RootLayout() {
  const [showDropDown, setShowDropDown] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileProdotti, setShowMobileProdotti] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
  }, []);
  const ToggleMobileProdotti = (event) => {
    event.preventDefault();
    setShowMobileProdotti((prev) => !prev);
    const menu = document.querySelector(".menus");
    if (showMobileProdotti) {
      menu.style.marginLeft = "20px";
    } else {
      menu.style.marginLeft = "0px";
    }
  };
  const ToggleDropDown = (event) => {
    event.preventDefault();

    setShowDropDown((prev) => !prev);
  };
  const ToggleMobileMenu = (event) => {
    event.preventDefault();
    setShowMobileMenu((prev) => !prev);
  };
  if (showDropDown) {
    document.querySelector("body").style.overflow = "hidden";
  } else {
    document.querySelector("body").style.overflow = "scroll";
  }

  const isBigScreen = useMediaQuery({ query: "(min-width: 1100px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1099px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width:  1099px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const location = useLocation();
  useEffect(() => {
    setShowDropDown(false);
    setShowMobileMenu(false);
    setShowMobileProdotti(false);
    const menu = document.querySelector(".menus");
    if (menu) menu.style.marginLeft = "20px";
  }, [location]);

  return (
    <>
      {isBigScreen && (
        <NewHeader
          ToggleDropDown={ToggleDropDown}
          showdropdown={showDropDown}
        />
      )}
      {showDropDown && <DropDown />}
      {isTablet && (
        <TabletHeader
          showMobileMenu={ToggleMobileMenu}
          isopen={showMobileMenu}
          showMobileProdotti={showMobileProdotti}
          ToggleMobileProdotti={ToggleMobileProdotti}
        />
      )}
      {isMobile && (
        <NewMobileHeader
          showMobileMenu={ToggleMobileMenu}
          isopen={showMobileMenu}
          showMobileProdotti={showMobileProdotti}
          ToggleMobileProdotti={ToggleMobileProdotti}
        />
      )}
      <Outlet />
      {isTabletOrMobile && <MobileFooter />}
      {isBigScreen && <Footer />}
    </>
  );
}
