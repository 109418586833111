import "../../../css/contatti.css";
import ContactForm from "./ContactForm";
import { useState, useContext } from "react";
import LanguageContext from "../../../context/language-context";
import useFetch from "../../../hooks/useFetch";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
export default function Contacts() {
  const [showForm, setShowForm] = useState(false);

  const showFormHandler = () => {
    setShowForm((prev) => !prev);
  };
  const languageCtx = useContext(LanguageContext);

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/contattis?locale=${languageCtx.languageData}`
  );
  if (loading) return <p>Loading...</p>;
  const content = data.data[0].attributes.Address;

  const translations = {
    it: {
      directions: "INDICAZIONI",
      contact: "CONTATTI",
    },
    en: {
      directions: "DIRECTIONS",
      contact: "CONTACT",
    },
    es: {
      directions: "DIRECCIONES",
      contact: "CONTACTO",
    },
    de: {
      directions: "WEGE",
      contact: "KONTAKT",
    },
    fr: {
      directions: "ITINÉRAIRES",
      contact: "CONTACT",
    },
  };

  return (
    <section className="contact-main">
      <div className="contact-head">{data.data[0].attributes.Heading}</div>
      <div className="contact-info d-flex">
        <div className="address">
          <p className="cell w-300">
            <div>
              <BlocksRenderer content={content} />
            </div>
            <a
              href="https://www.google.it/maps/place/SpagnolGroup+-+Mobil+Srl/@45.8707293,12.0779382,18z/data=!4m6!3m5!1s0x4779227c00527cd1:0x8b23e4774052195c!8m2!3d45.870872!4d12.0776748!16s%2Fg%2F11cn3m04_m?entry=ttu"
              target="_blank"
            >
              <button id="close" className="directions">
                {translations[languageCtx.languageData].directions}
              </button>
            </a>
          </p>

          <div className="cell w-300">
            <p>
              Tel
              <a
                className="number"
                href={`tel:${data.data[0].attributes.Telephone}`}
              >
                {data.data[0].attributes.Telephone}
              </a>
              <br />
              Fax
              <a className="number" href={`tel:${data.data[0].attributes.Fax}`}>
                {data.data[0].attributes.Fax}
              </a>
              <br />
              <a
                className="number"
                href={`mailto:${data.data[0].attributes.Email}`}
              >
                {data.data[0].attributes.Email}
              </a>
            </p>
            <button id="close" className="show-form" onClick={showFormHandler}>
              {translations[languageCtx.languageData].contact}
            </button>
          </div>
        </div>
      </div>
      {showForm && <ContactForm />}
    </section>
  );
}
