import "../../../css/Home.css";
import FlipBook from "./FlipBook";
import IndexVideo from "./indexVideo";
import News from "./News";
import useFetch from "../../../hooks/useFetch";
import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import LanguageContext from "../../../context/language-context";
export default function Home() {
  const languageCtx = useContext(LanguageContext);
  const isBigScreen = useMediaQuery({ query: "(min-width: 1100px)" });

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/homes?locale=${languageCtx.languageData}&populate[0]=Index_Elements.Image&populate[1]=Video&populate[2]=Mobile_video`
  );

  if (loading) return <p>Loading...</p>;
  return (
    <div className="mainIndex">
      <IndexVideo
        video={
          isBigScreen
            ? data.data[0].attributes.Video.data.attributes.url
            : data.data[0].attributes.Mobile_video.data.attributes.url
        }
      />
      <FlipBook
        text={data.data[0].attributes.Button_Text}
        intro={data.data[0].attributes.Text}
        link={data.data[0].attributes.FlipBook_Link}
      />
      {data.data[0].attributes.Index_Elements.map((item, index) => (
        <News
          key={index}
          news={item.Description}
          newsimage={item.Image.data.attributes.url}
        />
      ))}
    </div>
  );
}
