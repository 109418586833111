import { useState, useEffect } from "react";
import React from "react";
import LanguageContext from "./language-context";
function LanguageProvider(props) {
  const [languageData, setLanguage] = useState(() => {
    // Initialize the state with the value from local storage or default to "it"
    return localStorage.getItem("language") || "it";
  });

  function changeLanguage(val) {
    setLanguage(val);
    localStorage.setItem("language", val); // Update local storage
  }

  useEffect(() => {
    // Update local storage when languageData changes
    localStorage.setItem("language", languageData);
  }, [languageData]);

  const StateContext = {
    languageData: languageData,
    changeLanguage: changeLanguage,
  };

  return (
    <LanguageContext.Provider value={StateContext}>
      {props.children}
    </LanguageContext.Provider>
  );
}

export default LanguageProvider;
