import { useState, useContext } from "react";
import useInput from "../../../hooks/use-input";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import LanguageContext from "../../../context/language-context";

const MySwal = withReactContent(Swal);

const isNotEmpty = (value) => value.trim() !== "";
const isEmail = (value) => {
  return value.trim().length > 5 && value.includes("@");
};
export default function ContactForm() {
  const languageCtx = useContext(LanguageContext);
  const translations = {
    it: {
      heading: "Richiesta informazioni",
      description:
        "Per richiedere informazioni sui nostri prodotti e sul gruppo. (* Dati obbligatori).",
      name: "Nome",
      email: "E-mail",
      surname: "Cogonome",
      phone: "Telefono",
      country: "Nazione",
      province: "Provincia",
      city: "Città",
      request: "Richiesta",
      confirm:
        "Confermo la lettura dell' informativa sulla privacy e acconsento al trattamento dei dati personali.*",
      required: "campi obbligatori",
    },
    en: {
      heading: "Information Request",
      description:
        "To request information about our products and the group. (* Required fields).",
      name: "Name",
      email: "E-mail",
      surname: "Surname",
      phone: "Phone",
      country: "Country",
      province: "Province",
      city: "City",
      request: "Request",
      confirm:
        "I confirm that I have read the privacy policy and agree to the processing of personal data.*",
      required: "required fields",
    },
    es: {
      heading: "Solicitud de información",
      description:
        "Para solicitar información sobre nuestros productos y el grupo. (* Campos obligatorios).",
      name: "Nombre",
      email: "Correo electrónico",
      surname: "Apellido",
      phone: "Teléfono",
      country: "País",
      province: "Provincia",
      city: "Ciudad",
      request: "Solicitud",
      confirm:
        "Confirmo que he leído la política de privacidad y acepto el tratamiento de datos personales.*",
      required: "campos obligatorios",
    },
    de: {
      heading: "Informationsanfrage",
      description:
        "Um Informationen über unsere Produkte und die Gruppe zu erhalten. (* Pflichtfelder).",
      name: "Name",
      email: "E-Mail",
      surname: "Nachname",
      phone: "Telefon",
      country: "Land",
      province: "Provinz",
      city: "Stadt",
      request: "Anfrage",
      confirm:
        "Ich bestätige, dass ich die Datenschutzerklärung gelesen habe und der Verarbeitung personenbezogener Daten zustimme.*",
      required: "Pflichtfelder",
    },
    fr: {
      heading: "Demande d'information",
      description:
        "Pour demander des informations sur nos produits et notre groupe. (* Champs obligatoires).",
      name: "Prénom",
      email: "Courriel",
      surname: "Nom de famille",
      phone: "Téléphone",
      country: "Pays",
      province: "Province",
      city: "Ville",
      request: "Demande",
      confirm:
        "Je confirme avoir lu la politique de confidentialité et accepte le traitement des données personnelles.*",
      required: "champs obligatoires",
    },
  };

  const {
    value: nameValue,
    isValid: nameIsValid,
    hasError: nameError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetname,
  } = useInput(isNotEmpty);
  const {
    value: provinceValue,
    isValid: provinceIsValid,
    hasError: provinceError,
    valueChangeHandler: provinceChangeHandler,
    inputBlurHandler: provinceBlurHandler,
    reset: resetprovince,
  } = useInput(isNotEmpty);
  const {
    value: phoneValue,
    isValid: phoneIsValid,
    hasError: phoneError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    reset: resetphone,
  } = useInput(isNotEmpty);
  const {
    value: cityValue,
    isValid: cityIsValid,
    hasError: cityError,
    valueChangeHandler: cityChangeHandler,
    inputBlurHandler: cityBlurHandler,
    reset: resetcity,
  } = useInput(isNotEmpty);
  const {
    value: surnameValue,
    isValid: surnameIsValid,
    hasError: surnameError,
    valueChangeHandler: surnameChangeHandler,
    inputBlurHandler: surnameBlurHandler,
    reset: resetsurname,
  } = useInput(isNotEmpty);
  const {
    value: messageValue,
    isValid: messageIsValid,
    hasError: messageError,
    valueChangeHandler: messageChangeHandler,
    inputBlurHandler: messageBlurHandler,
    reset: resetmessage,
  } = useInput(isNotEmpty);

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetemail,
  } = useInput(isEmail);
  const {
    value: countryValue,
    isValid: countryIsValid,
    hasError: countryError,
    valueChangeHandler: countryChangeHandler,
    inputBlurHandler: countryBlurHandler,
    reset: resetcountry,
  } = useInput(isEmail);

  let formIsValid = false;
  if (nameIsValid && surnameIsValid && messageIsValid) {
    formIsValid = true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nameIsValid && surnameIsValid && messageIsValid && emailIsValid) {
      const formData = new FormData();
      formData.append("name", nameValue);
      formData.append("surname", surnameValue);
      formData.append("phone", phoneValue);
      formData.append("province", provinceValue);
      formData.append("city", cityValue);
      formData.append("country", countryValue);
      formData.append("email", emailValue);
      formData.append("message", messageValue);

      // Send form data to the server
      try {
        const response = await fetch("contact.php", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          if (data.status === "success") {
            MySwal.fire({
              icon: "success",
              title: "Success",
              text: data.message,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }
        } else {
          // Show error message to the user
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Form submission failed",
          });
        }
      } catch (error) {
        console.error("Form submission failed", error);
      }
      resetemail();
      resetsurname();
      resetphone();
      resetname();
      resetmessage();
      resetprovince();
      resetcity();
      resetcountry();
    } else {
      return;
    }
    // Perform form validation if needed

    // Send form data to the server
  };
  return (
    <div className="cont-form">
      <form
        method="post"
        action="contact.php"
        className="cs-form"
        onSubmit={handleSubmit}
      >
        <h2 className="info">
          {translations[languageCtx.languageData].heading}
        </h2>
        <p className="details">
          {translations[languageCtx.languageData].description}
        </p>
        <br />
        <label htmlFor="name" className="name">
          {translations[languageCtx.languageData].name}*
        </label>
        <input
          value={nameValue}
          type="text"
          id="name"
          name="name"
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
        />
        {nameError && <p className="error-text">Name cannot be empty</p>}
        <small />
        <label htmlFor="surmane" className="surname">
          {translations[languageCtx.languageData].surname}*
        </label>
        <input
          value={surnameValue}
          type="text"
          id="surname"
          name="surname"
          onChange={surnameChangeHandler}
          onBlur={surnameBlurHandler}
        />
        {surnameError && <p className="error-text">Surname cannot be empty</p>}
        <small />
        <label htmlFor="email" className="emails surname">
          {translations[languageCtx.languageData].email}*
        </label>
        <input
          type="email"
          id="email"
          name="email"
          onChange={emailChangeHandler}
          value={emailValue}
          onBlur={emailBlurHandler}
        />
        {emailError && <p className="error-text">Enter a valid email!</p>}

        <label htmlFor="telephone" className="addresss">
          {translations[languageCtx.languageData].phone}
        </label>
        <input
          type="text"
          id="address"
          name="telephone"
          onChange={phoneChangeHandler}
          value={phoneValue}
          onBlur={phoneBlurHandler}
        />
        <label htmlFor="country" className="addresss">
          {translations[languageCtx.languageData].country}
        </label>
        <input
          type="text"
          id="address"
          name="country"
          onChange={countryChangeHandler}
          value={countryValue}
          onBlur={countryBlurHandler}
        />
        <label htmlFor="city" className="addresss">
          {translations[languageCtx.languageData].city}
        </label>
        <input
          type="text"
          id="address"
          name="city"
          onChange={cityChangeHandler}
          value={cityValue}
          onBlur={cityBlurHandler}
        />
        <label htmlFor="province" className="addresss">
          {translations[languageCtx.languageData].province}
        </label>
        <input
          type="text"
          id="address"
          name="province"
          onChange={provinceChangeHandler}
          value={provinceValue}
          onBlur={provinceBlurHandler}
        />

        <label htmlFor="request" className="request italian">
          {translations[languageCtx.languageData].request}*
        </label>
        <textarea
          type="text"
          name="request"
          id="address"
          required=""
          value={messageValue}
          onChange={messageChangeHandler}
          onBlur={messageBlurHandler}
          defaultValue={""}
        />
        {messageError && <p className="error-text">Request cannot be empty</p>}
        <small />
        <p className="mg-0">
          *{translations[languageCtx.languageData].required}
        </p>
        <label htmlFor="confirm" className="confirm italian">
          <input type="checkbox" name="confirm" id="confirm" required />
          <span className="checkmark verify" />
          <a href="/policy" id="popup-link">
            {translations[languageCtx.languageData].confirm}
          </a>
        </label>
        <button
          type="submit"
          className="submit btn-lined1"
          id="formsubmit"
          disabled={!formIsValid}
        >
          SUBMIT
        </button>
      </form>
    </div>
  );
}
