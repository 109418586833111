import SingleProduct from "./SingleProduct";
import { useState, useEffect } from "react";
import { MdCancel } from "react-icons/md";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";
import "../../../css/gallery.css";

export default function ProductList(props) {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imagessrc, setImagesSrc] = useState([]);

  useEffect(() => {
    // Select all img elements within a div with the class name 'product-box'
    const images = document.querySelectorAll(".product-box img");
    const srcArray = [];
    // Loop through each img element and retrieve the src attribute
    images.forEach((img) => {
      const src = img.getAttribute("src");
      srcArray.push(src);
      // Or do whatever you want with the src attribute
    });
    setImagesSrc(srcArray);
  }, []);

  const openLightbox = (e, index) => {
    e.preventDefault();
    setCurrentImageIndex(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  const gotoPrevious = () => {
    if (currentImageIndex === 0) {
      setCurrentImageIndex(imagessrc.length - 1);
    } else {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const gotoNext = () => {
    if (currentImageIndex === imagessrc.length - 1) {
      setCurrentImageIndex(0);
    } else {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  return (
    <>
      {props.products &&
        props.products.map((item, index) => (
          <div className="product-box" key={index}>
            <SingleProduct
              bigimage={item.bigimage.data.attributes.url}
              mediumimage={
                item.mediumimage.data?.attributes.url || "" // Corrected syntax
              }
              smallimage={
                item.smallimage.data?.attributes.url || "" // Corrected syntax
              }
              productdescription={item.description}
              onClickHandler={(e) => openLightbox(e, index)}
            />
          </div>
        ))}
      {lightboxIsOpen && (
        <div className="image-popup">
          <button onClick={closeLightbox} className="close-button">
            <MdCancel />
          </button>
          <button onClick={gotoPrevious} className="prev-button">
            <GrPrevious />
          </button>
          <img
            src={imagessrc[currentImageIndex]} // Use the images from imagessrc
            alt="Selected"
          />
          <button onClick={gotoNext} className="next-button">
            <GrNext />
          </button>
        </div>
      )}
    </>
  );
}
