import "../../css/MobileMenu.css";
import Socials from "./Socials";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { useSpring, animated } from "@react-spring/web";
import { useContext, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import useFetch from "../../hooks/useFetch";
import { Link } from "react-router-dom";
import LanguageContext from "../../context/language-context";
import MobileDropDown from "./MobileDropDown";
export default function MobileMenu(props) {
  const springs = useSpring({
    from: { x: -100 },
    to: { x: 0 },
  });
  const languageCtx = useContext(LanguageContext);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/headers?locale=${languageCtx.languageData}&populate[0]=Logo`
  );

  if (loading) return <p>Loading...</p>;
  return (
    <animated.div
      className={`mobileView menuMobile ${props.isopen ? "showing" : ""}`}
      style={{ ...springs }}
    >
      <div className="mobilemenu">
        <div className="menue">
          <ul className="menus">
            <li className="space">
              <a
                href="#"
                className="hasDropDown heading prodotti"
                onClick={props.ToggleMobileProdotti}
              >
                {data.data[0].attributes.Prodotti}
                <IoArrowForwardCircleSharp />
              </a>
              <MobileDropDown
                isopen={props.showMobileProdotti}
                ToggleMenu={props.ToggleMobileProdotti}
              />
            </li>
            <li className="space">
              <Link to="/cataloghi" className="heading cataloghi">
                {data.data[0].attributes.Cataloghi}
              </Link>
            </li>
            <li className="space">
              <Link to="/azienda" className="heading contatti">
                {data.data[0].attributes.Azienda}
              </Link>
            </li>
            <li className="space">
              <Link to="/rivenditori" className="heading rivenditory">
                {data.data[0].attributes.Rivenditori}
              </Link>
            </li>
            <li className="space">
              <Link to="/news" className="heading contatti">
                {data.data[0].attributes.News}
              </Link>
            </li>
            <li className="space">
              <Link to="/contatti" className="heading contatti">
                {data.data[0].attributes.Contatti}
              </Link>
            </li>
          </ul>
          {isMobile && (
            <Socials
              area={data.data[0].attributes.Area_Riservata}
              facebook={data.data[0].attributes.Facebook}
              instagram={data.data[0].attributes.Instagram}
              x={data.data[0].attributes.X}
              pinterest={data.data[0].attributes.Pinterest}
              youtube={data.data[0].attributes.Youtube}
            />
          )}
        </div>
      </div>
    </animated.div>
  );
}
