export default function PageHeading(props) {
  return (
    <div
      className="rivenditori-head"
      // style={{
      //   backgroundImage: `url(https://spagnoladmin.spagnolmobili.it${props.image})`,
      // }}
    >
      <img src={`https://spagnoladmin.spagnolmobili.it${props.image}`} />
    </div>
  );
}
