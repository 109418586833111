import {
  FaFacebookF,
  FaInstagram,
  FaXTwitter,
  FaPinterest,
  FaLock,
  FaYoutube,
} from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
export default function Socials(props) {
  return (
    <div className="d-flex socials">
      <a
        className="header-social-icon-widget-holder header-icon-has-hover"
        href={props.facebook}
        target="_blank"
      >
        <FaFacebookF />
      </a>
      <a
        className="header-social-icon-widget-holder header-icon-has-hover"
        href={props.instagram}
        target="_blank"
      >
        <FaInstagram />
      </a>
      <a
        className="header-social-icon-widget-holder header-icon-has-hover"
        href={props.x}
        target="_blank"
      >
        <FaXTwitter />
      </a>
      <a
        className="header-social-icon-widget-holder header-icon-has-hover"
        href={props.pinterest}
        target="_blank"
      >
        <FaPinterest />
      </a>
      <a
        className="header-social-icon-widget-holder header-icon-has-hover"
        href={props.youtube}
        target="_blank"
      >
        <IoLogoYoutube />
      </a>
      <a
        className="header-icon-widget-holder header-icon-has-hover edgtf-text-left"
        data-hover-color="#ffffff"
        href="#"
        target="_self"
      >
        <span className="header-icon-text riservata">{props.area}</span>
        &nbsp;&nbsp;
        <FaLock />
      </a>
    </div>
  );
}
