export default function FlipBook(props) {
  return (
    <div className="container">
      <div className="row row--2">
        <div className="catbutton">
          <p className="paragraph-2">{props.intro}</p>
          <a href="/cataloghi" id="contact-btn" className="">
            <button id="close" className="scopri italian">
              {props.text}
            </button>
          </a>
        </div>
      </div>
      <iframe
        allowFullScreen="allowfullscreen"
        scrolling="no"
        className="fp-iframe"
        // src={props.link}
        src="https://heyzine.com/flip-book/5bc5790f5a.html"
        style={{ border: "1px solid lightgray", width: "100%", height: 800 }}
      />
    </div>
  );
}
