import { useContext } from "react";
import LanguageContext from "../../context/language-context";

export default function WorldAgents(props) {
  const languageCtx = useContext(LanguageContext);
  const handleOptionChange = (event) => {
    const selectElement = event.target;
    const selectedOptionText =
      selectElement.options[selectElement.selectedIndex].text;

    props.handleOptionChange(selectedOptionText);
  };
  const translations = {
    en: {
      title: "Continent*",
      description: "Country*",
      required: "*required fields",
      search: "SEARCH",
    },
    fr: {
      title: "Continent*",
      description: "Village*",
      required: "*champs obligatoires",
      search: "RECHERCHE",
    },
    it: {
      title: "Continente*",
      description: "Paese*",
      required: "*campi richiesti",
      search: "RICERCA",
    },
    de: {
      title: "Kontinent*",
      description: "Dorf*",
      required: "*Benötigte Felder",
      search: "SUCHEN",
    },
    es: {
      title: "Continente*",
      description: "Aldea*",
      required: "*Campos requeridos",
      search: "BUSCAR",
    },
  };
  return (
    <div className="regions">
      <span className="custom-dropdown custom-dropdown--white custom-dropdown--large">
        <select className="custom-dropdown__select custom-dropdown__select--white">
          <option value="" class="trcontinent">
            {translations[languageCtx.languageData].title}
          </option>
          <option value="europe">Europe</option>
        </select>
      </span>
      <span className="custom-dropdown custom-dropdown--white custom-dropdown--large">
        <select
          className="custom-dropdown__select custom-dropdown__select--white"
          onChange={handleOptionChange}
        >
          <option value="" class="trcountry">
            {translations[languageCtx.languageData].description}
          </option>
          <option value="AL">Albania</option>
          <option value="AD">Andorra</option>
          <option value="AT">Austria</option>
          <option value="BY">Belarus</option>
          <option value="BE">Belgium</option>
          <option value="BA">Bosnia and Herzegovina</option>
          <option value="BG">Bulgaria</option>
          <option value="HR">Croatia (Hrvatska)</option>
          <option value="CY">Cyprus</option>
          <option value="CZ">Czech Republic</option>
          <option value="FR">France</option>
          <option value="GI">Gibraltar</option>
          <option value="DE">Germany</option>
          <option value="GR">Greece</option>
          <option value="VA">Holy See (Vatican City State)</option>
          <option value="HU">Hungary</option>
          <option value="IT">Italy</option>
          <option value="LI">Liechtenstein</option>
          <option value="LU">Luxembourg</option>
          <option value="MK">Macedonia</option>
          <option value="MT">Malta</option>
          <option value="MD">Moldova</option>
          <option value="MC">Monaco</option>
          <option value="ME">Montenegro</option>
          <option value="NL">Netherlands</option>
          <option value="PL">Poland</option>
          <option value="PT">Portugal</option>
          <option value="RO">Romania</option>
          <option value="SM">San Marino</option>
          <option value="RS">Serbia</option>
          <option value="SK">Slovakia</option>
          <option value="SI">Slovenia</option>
          <option value="ES">Spain</option>
          <option value="UA">Ukraine</option>
          <option value="GB">United Kingdom</option>
          <option value="DK">Denmark</option>
          <option value="EE">Estonia</option>
          <option value="FO">Faroe Islands</option>
          <option value="FI">Finland</option>
          <option value="GL">Greenland</option>
          <option value="IS">Iceland</option>
          <option value="IE">Ireland</option>
          <option value="LV">Latvia</option>
          <option value="LT">Lithuania</option>
          <option value="NO">Norway</option>
          <option value="SJ">Svalbard and Jan Mayen Islands</option>
          <option value="SE">Sweden</option>
          <option value="CH">Switzerland</option>
          <option value="TR">Turkey</option>
        </select>
      </span>
      <div className="align-center">
        <p className="paragraph-21 required">
          {translations[languageCtx.languageData].required}
        </p>
      </div>
      <a id="contact-btn" className="fom">
        <button
          id="close"
          className="searchbtn"
          onClick={props.searchAgentHandler}
        >
          {translations[languageCtx.languageData].search}
        </button>
      </a>
    </div>
  );
}
