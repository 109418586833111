import Socials from "./Socials";
import "../../css/Header.css";
import useFetch from "../../hooks/useFetch";
import { useContext } from "react";
import LanguageContext from "../../context/language-context";
import { GiHamburgerMenu } from "react-icons/gi";
import LanguageMenu from "./LanguageMenu";
import MobileMenu from "./MobileMenu";

export default function TabletHeader(props) {
  const languageCtx = useContext(LanguageContext);
  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/headers?locale=${languageCtx.languageData}&populate[0]=Logo`
  );

  if (loading) return <p>Loading...</p>;
  return (
    <div className="Tabletview">
      <nav className="navbar navbar-light tablet">
        <div className="container-fluid">
          <div className="navbar-brand">
            <a href="/">
              <img
                src={`https://spagnoladmin.spagnolmobili.it${data.data[0].attributes.Logo.data.attributes.url}`}
              />
            </a>
          </div>
          <Socials
            area={data.data[0].attributes.Area_Riservata}
            facebook={data.data[0].attributes.Facebook}
            instagram={data.data[0].attributes.Instagram}
            x={data.data[0].attributes.X}
            pinterest={data.data[0].attributes.Pinterest}
            youtube={data.data[0].attributes.Youtube}
          />
          <LanguageMenu />
          <div className="d-flex header__toggle">
            <GiHamburgerMenu onClick={props.showMobileMenu} />
          </div>
        </div>
      </nav>
      <MobileMenu
        isopen={props.isopen}
        showMobileProdotti={props.showMobileProdotti}
        ToggleMobileProdotti={props.ToggleMobileProdotti}
      />
    </div>
  );
}
