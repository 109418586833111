import FooterSocials from "./FooterSocials";
import "../../css/Footer.css";
import useFetch from "../../hooks/useFetch";

export default function Footer() {
  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/footers?populate[0]=Group_Logo&populate[1]=Logo_and_Link.Logo`
  );

  if (loading) return <p>Loading...</p>;
  return (
    <>
      <footer className="container-fluid footer d-flex">
        <div className="footerLogo">
          <div className="Gruppo">
            <div>
              <a href="/">
                <img
                  loading="lazy"
                  className="vw4"
                  src={`https://spagnoladmin.spagnolmobili.it${data.data[0].attributes.Group_Logo.data.attributes.url}`}
                />
              </a>
            </div>
            <div className="newgrouppo">
              <a
                href={data.data[0].attributes.Logo_and_Link[0].link}
                target="_blank"
              >
                <img
                  loading="lazy"
                  className="vw13"
                  src={`https://spagnoladmin.spagnolmobili.it${data.data[0].attributes.Logo_and_Link[0].Logo.data.attributes.url}`}
                  alt=""
                />
              </a>
              <a
                href={data.data[0].attributes.Logo_and_Link[1].link}
                target="_blank"
              >
                <img
                  loading="lazy"
                  className="vw13"
                  src={`https://spagnoladmin.spagnolmobili.it${data.data[0].attributes.Logo_and_Link[1].Logo.data.attributes.url}`}
                  alt=""
                />
              </a>
              <a
                href={data.data[0].attributes.Logo_and_Link[2].link}
                className="m7"
              >
                <img
                  loading="lazy"
                  className="vw13"
                  src={`https://spagnoladmin.spagnolmobili.it${data.data[0].attributes.Logo_and_Link[2].Logo.data.attributes.url}`}
                  alt=""
                />
              </a>
              <a
                href={data.data[0].attributes.Logo_and_Link[3].link}
                target="_blank"
              >
                <img
                  loading="lazy"
                  className="vw13"
                  src={`https://spagnoladmin.spagnolmobili.it${data.data[0].attributes.Logo_and_Link[3].Logo.data.attributes.url}`}
                  alt=""
                />
              </a>
              <a
                href={data.data[0].attributes.Logo_and_Link[4].link}
                target="_blank"
                className="m7"
              >
                <img
                  loading="lazy"
                  src={`https://spagnoladmin.spagnolmobili.it${data.data[0].attributes.Logo_and_Link[4].Logo.data.attributes.url}`}
                  alt=""
                />
              </a>
            </div>
          </div>
          <ul className="f20">
            <li>All rights reserved</li>
            <li>
              <a href="/policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/cookies">Cookies</a>
            </li>
          </ul>
        </div>
        <div className="Sociallogo">
          <FooterSocials
            facebook={data.data[0].attributes.Facebook}
            instagram={data.data[0].attributes.Instagram}
            x={data.data[0].attributes.X}
            pinterest={data.data[0].attributes.Pinterest}
            youtube={data.data[0].attributes.Youtube}
          />
          <a href="https://i-gerald.com/" className="iGerald" target="_blank">
            website by iGerald
          </a>
        </div>
      </footer>
    </>
  );
}
