import { Link } from "react-router-dom";
export default function Popup(props) {
  return (
    <section className="side-popup">
      <ul>
        {props.products.map((item, index) => {
          return (
            <li className="stores popup" key={index}>
              <a
                href={
                  item.Pdf.data
                    ? `https://spagnoladmin.spagnolmobili.it${item.Pdf.data[0].attributes.url}`
                    : item.Link
                }
              >
                <span className="icon-wrapper">
                  <img
                    className="icon"
                    src={`https://spagnoladmin.spagnolmobili.it${item.Icon.data.attributes.url}`}
                  />
                </span>
                <span className="side-label">{item.Label}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
