import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import LanguageContext from "../../../context/language-context";
import "../../../css/News.css";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
export default function NewsInner(props) {
  const { slug } = useParams();

  const languageCtx = useContext(LanguageContext);
  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/newss?locale=${languageCtx.languageData}&populate[0]=Page_Heading&populate[1]=News_Items&populate[2]=News_Items.Banner_Image&populate[3]=News_Items.Inner_Images`
  );
  if (loading) return <p>Loading...</p>;
  const { data: details, meta } = data;
  const selectedProducts = details[0].attributes.News_Items.find(
    (item) => item.Title == slug
  );

  const content = selectedProducts.Description;
  let productTitle = selectedProducts.Title;

  if (productTitle.includes(`-`)) {
    productTitle = productTitle.split(`-`).join(` `);
  }
  return (
    <section className="news-inner">
      <div
        className="page-heading"
        style={{
          backgroundImage: `url(https://spagnoladmin.spagnolmobili.it${selectedProducts.Banner_Image.data.attributes.url})`,
        }}
      ></div>
      <div className="news-description">
        <h1 className="news-title">{productTitle}</h1>

        <BlocksRenderer content={content} />
      </div>
      <Carousel autoPlay={true} infiniteLoop={true} className="carousel-50">
        {selectedProducts.Inner_Images.data.map((item, index) => (
          <img
            key={index}
            src={`https://spagnoladmin.spagnolmobili.it${item.attributes.url}`}
            alt="Bed"
          />
        ))}
      </Carousel>
    </section>
  );
}
