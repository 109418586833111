import { Link } from "react-router-dom";
export default function SingleProduct(props) {
  return (
    <Link
      to={`/sedie/${props.slug}/${props.product_name.toLowerCase()}`}
      id="img-link"
      className=""
    >
      <div className="sec-12 padding--lr-0">
        <img
          className="firstImg"
          id="boxImg"
          src={`https://spagnoladmin.spagnolmobili.it${props.image}`}
          alt="armadi ad anta battente design"
        />
      </div>

      <div id="col-h" className="sec-12">
        <h3 id="heading-01" className="firstImgcontent">
          {props.productname}
        </h3>
      </div>
    </Link>
  );
}
