import PageHeading from "./PageHeading";
import { Parallax } from "react-scroll-parallax";
import CountUp from "react-countup";
import { useState, useContext } from "react";
import "../../../css/azienda.css";
import useFetch from "../../../hooks/useFetch";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import LanguageContext from "../../../context/language-context";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Azienda() {
  const [showFullText, setShowFullText] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);

  // State to keep track of the active index
  const [activeIndex, setActiveIndex] = useState(0);

  // Function to handle click on list items
  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  const toggleText2 = (index) => {
    if (expandedItems.includes(index)) {
      setExpandedItems(
        expandedItems.filter((itemIndex) => itemIndex !== index)
      );
    } else {
      setExpandedItems([...expandedItems, index]);
    }
  };
  const translations = {
    en: {
      more: "Read More",
      less: "Read Less",
    },
    fr: {
      more: "Lire Plus",
      less: "Lire Moins",
    },
    de: {
      more: "Mehr Lesen",
      less: "Weniger Lesen",
    },
    ru: {
      more: "Читать далее",
      less: "Читать меньше",
    },
    es: {
      more: "Leer Más",
      less: "Leer Menos",
    },
    it: {
      more: "Leggi di più",
      less: "Leggi Meno",
    },
  };

  const languageCtx = useContext(LanguageContext);
  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/aziendas?locale=${languageCtx.languageData}&populate[0]=Banner_Image&populate[1]=Chi_Siamo&populate[2]=Middle_Section.Item.Image&populate[3]=Middle_Image&populate[4]=Middle_text&populate[5]=Number_section.numbers&populate[6]=Bottom_section&populate[7]=Chi_Siamo.Image&populate[8]=Number_section.Heading&populate[9]=Bottom_section.Images`
  );
  if (loading) return <p>Loading...</p>;
  const { data: details, meta } = data;
  const chisiamofull = details[0].attributes.Chi_Siamo.Description;
  const chisiamopreview = details[0].attributes.Chi_Siamo.Preview;
  const middletext = details[0].attributes.Middle_text;

  return (
    <div className="mg-3">
      <PageHeading
        img={details[0].attributes.Banner_Image.data.attributes.url}
      />
      <div className="first-section">
        <div className="section-inner">
          <div className="left-text">
            <Parallax speed={3}>
              <h1>{details[0].attributes.Chi_Siamo.Heading}</h1> <br />
              {showFullText ? (
                <>
                  <BlocksRenderer content={chisiamofull} />
                  <br />

                  <a className=" underline-hover-effect" onClick={toggleText}>
                    {translations[languageCtx.languageData].less}
                  </a>
                </>
              ) : (
                <>
                  <BlocksRenderer content={chisiamopreview} />
                  <br />
                  <a className="underline-hover-effect" onClick={toggleText}>
                    {translations[languageCtx.languageData].more}
                  </a>
                </>
              )}
            </Parallax>
          </div>
          <div className="right-text">
            <Parallax speed={3}>
              <div className="hovereffect">
                <a className="test">
                  <img
                    src={`https://spagnoladmin.spagnolmobili.it${details[0].attributes.Chi_Siamo.Image.data.attributes.url}`}
                  />
                </a>
              </div>
            </Parallax>
          </div>
        </div>
      </div>

      <div className="first-section bg-color">
        <div className="section-inner">
          {details[0].attributes.Middle_Section.Item.map((item, index) => {
            const itempreview = item.Preview;
            const itemfull = item.Description;
            const isExpanded = expandedItems.includes(index);
            return (
              <div className="left-text2" key={index}>
                <div className="img-top">
                  <Parallax speed={3}>
                    <div className="hovereffect">
                      <a className="test">
                        <img
                          src={`https://spagnoladmin.spagnolmobili.it${item.Image.data.attributes.url}`}
                        />
                      </a>
                    </div>
                  </Parallax>
                </div>
                <div className="description">
                  <Parallax speed={3}>
                    <h1>{item.Heading}</h1> <br />
                    {isExpanded ? (
                      <>
                        <BlocksRenderer content={itemfull} />
                        <a
                          className="underline-hover-effect"
                          onClick={() => toggleText2(index)}
                        >
                          {translations[languageCtx.languageData].less}
                        </a>
                      </>
                    ) : (
                      <>
                        <BlocksRenderer content={itempreview} />
                        <br />
                        <a
                          className="underline-hover-effect"
                          onClick={() => toggleText2(index)}
                        >
                          {translations[languageCtx.languageData].more}
                        </a>
                      </>
                    )}
                  </Parallax>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <section className="middle-image" data-scroll-section>
        <div className="mid-img">
          <Parallax speed={0}>
            <div className="hovereffect">
              <a className="test">
                <img
                  src={`https://spagnoladmin.spagnolmobili.it${details[0].attributes.Middle_Image.data.attributes.url}`}
                />
              </a>
            </div>
          </Parallax>
        </div>
      </section>
      <div className="second-text" data-scroll-section>
        <Parallax speed={3}>
          <BlocksRenderer content={middletext} />
        </Parallax>
      </div>
      <section className="bg-grey" data-scroll-section="">
        <div className="maxWidth">
          <div className="is-inview">
            <h3 className="number-bold">
              {details[0].attributes.Number_section.Heading}
            </h3>
          </div>
          <div className="numeri">
            <div className="two-column">
              {details[0].attributes.Number_section.numbers
                .slice(0, 3)
                .map((item, index) => (
                  <div className="is-inview" key={index}>
                    {index !== 0 && <span className="number-count">+</span>}
                    <CountUp
                      end={item.number}
                      decimal=""
                      decimals={item.decmials}
                      className="number-count"
                    />
                    <label className="suffix">{item.text}</label>
                  </div>
                ))}
            </div>

            {details[0].attributes.Number_section.numbers
              .slice(3)
              .map((item, index) => {
                return (
                  <div className="is-inview" key={index}>
                    <span className="number-count">+</span>
                    <CountUp
                      end={item.number}
                      decimal="."
                      decimals={item.decmials}
                      className="number-count"
                    />
                    <label className="suffix">{item.text}</label>
                  </div>
                );
              })}
          </div>
        </div>
      </section>

      <section className="index-section bg-color">
        <div className="index-inner">
          <ul className="index-nav">
            {details[0].attributes.Bottom_section.map((item, index) => {
              const title = item.Title;
              return (
                <li
                  key={index}
                  className={activeIndex === index ? "active-li" : ""}
                  onClick={() => handleItemClick(index)}
                >
                  <BlocksRenderer content={title} />
                </li>
              );
            })}
          </ul>
          {details[0].attributes.Bottom_section.map((item, index) => {
            const descriptions = item.Description;
            return (
              <div
                key={index}
                className={`index-section-inner ${
                  activeIndex === index ? "active-div" : ""
                }`}
              >
                <div className="left-text">
                  <Parallax speed={3}>
                    <BlocksRenderer content={descriptions} />
                  </Parallax>
                </div>
                <div className="right-text">
                  <Parallax speed={3}>
                    <Carousel
                      showThumbs={false}
                      autoPlay={true}
                      infiniteLoop={true}
                      showIndicators={false}
                    >
                      {item.Images.data.map((image, imageIndex) => (
                        <img
                          key={imageIndex}
                          src={`https://spagnoladmin.spagnolmobili.it${image.attributes.url}`}
                        />
                      ))}
                    </Carousel>
                  </Parallax>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}
