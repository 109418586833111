import "../../../css/cookies.css";
export default function Cookies() {
  return (
    <div class="main-section privacybody">
      <h3>Cosa sono i cookie?</h3>
      <p>
        I cookie sono piccoli file, spesso crittografati, che si trovano nelle
        directory del browser. I cookie vengono utilizzati dai proprietari del
        sito Web per eseguire attività e aiutare nella gestione del sito Web.
        Alcuni cookie sono addirittura vitali per il corretto funzionamento di
        un sito web. Ad esempio, se stai utilizzando un negozio online e
        desideri inserire un articolo nel tuo carrello, dovrai accettare i
        cookie per eseguire tale azione.
      </p>
      <p>
        I cookie sono inoltre necessari per conservare le informazioni
        dell'utente, come nomi utente, password, attività di navigazione e per
        creare annunci personalizzati per l'utente. Per fare ciò, una volta che
        visiti un sito Web, invia i cookie al tuo dispositivo e carica una
        versione personalizzata per te. I cookie possono essere creati non solo
        dai siti Web, ma anche tramite widget, annunci o altri elementi del sito
        Web.
      </p>
      <p>
        Sebbene esistano molti modi per crearli, i cookie vengono rilasciati nel
        browser dell'utente dopo che è stato dato il consenso al tracciamento.
        Solo i cookie strettamente necessari sono consentiti senza il consenso
        dell'utente. È importante sapere che senza i cookie strettamente
        necessari sarebbe impossibile salvare i dati su un sito web. Ad esempio,
        senza i cookie, non saresti in grado di salvare i dettagli di accesso su
        un sito Web o salvare gli articoli nel carrello dopo aver chiuso la
        finestra del browser.
      </p>
      <p>
        Nel mondo dei cookie, tuttavia, non c'è molto margine di miglioramento
        su come dovrebbero agire. Esistono molte leggi in tutto il mondo che
        regolano l'utilizzo dei cookie e il tracciamento dei dati personali,
        incluso il regolamento generale sulla protezione dei dati (GDPR)
        dell'UE.
      </p>
      <h3>Come vengono utilizzati i cookie?</h3>
      <p>
        Esistono molti modi in cui i cookie possono essere utilizzati dai siti
        web. Una volta che un utente consente a un sito Web di rilasciare i
        cookie, inizia a monitorare i dati personali, l'attività di navigazione
        dell'utente e persino l'indirizzo IP, la cronologia degli acquisti e le
        preferenze. E con queste informazioni i siti web possono fare una serie
        di cose:
      </p>
      <ul id="cookie-ul">
        <li>
          <b>Ottimizza le prestazioni del sito web.</b> I proprietari di siti
          web vogliono avere informazioni sulle prestazioni del sito web, su
          come gli utenti trascorrono del tempo su di esso e per quali motivi
          decidono di uscire. I cookie tracciano le azioni dell'utente e aiutano
          i proprietari del sito web a migliorare l'esperienza di navigazione.
        </li>
        <li>
          <b>Esegui l'analisi del sito web.</b> I siti Web utilizzano i cookie
          anche per ottenere informazioni su come si comportano i loro utenti.
          Tracciano la posizione geografica e le preferenze al fine di
          ottimizzare il proprio sito Web per particolari regioni a cui si
          rivolgono in modo da poter indirizzare più traffico.
        </li>
        <li>
          <b>Pubblicizza.</b> I siti Web raccolgono anche dati con i cookie in
          modo che possano mostrare gli annunci più pertinenti. Questa pratica
          può aumentare le entrate e le vendite poiché i visitatori possono
          vedere annunci adatti solo a loro.
        </li>
      </ul>
      <p>
        Un altro modo in cui i siti web utilizzano i cookie è quello di eseguire
        determinate funzioni. Gli utenti non possono disattivare questi cookie
        se desiderano accedere a tutte le funzionalità di un sito Web e sono
        chiamati cookie strettamente necessari. Questi cookie sono necessari, ad
        esempio, per salvare le informazioni dell'utente come i dettagli di
        accesso o per conservare gli articoli dell'utente in un carrello anche
        dopo la chiusura della finestra del browser. Senza i cookie, i siti Web
        non sarebbero in grado di ricordare tali cose. Pertanto, sono i cookie
        più importanti per il corretto funzionamento di un sito web.
      </p>

      <h3>Tutti i cookie sono uguali?</h3>
      <p>
        No, non tutti i cookie sono uguali, possono avere scopi diversi. I
        cookie sono solitamente suddivisi in due gruppi distinti:
      </p>
      <ul id="cookie-ul">
        <li>
          Cookie di sessione: questi cookie sono temporanei e vengono cancellati
          quando chiudi il browser dopo aver visitato un sito web. La prossima
          volta che visiterai lo stesso sito web{" "}
          <strong>non ti riconoscerà</strong> poiché i cookie di sessione non
          tengono traccia delle informazioni a fini di ottimizzazione.
        </li>
        <li>
          Cookie persistenti: questi cookie rimangono sul tuo disco rigido fino
          a quando non li cancelli o scadono. Tali cookie vengono utilizzati per
          ottimizzare l'esperienza di navigazione per il visitatore e per
          <strong>ricordare le azioni</strong> che un visitatore ha compiuto
          mentre si spostava all'interno del sito web. I cookie persistenti
          possono quindi capire cosa piace alle persone del sito Web per aiutare
          a migliorare l'esperienza dell'utente. Il cookie persistente più
          popolare è un cookie di Google Analytics: molti siti Web in tutto il
          mondo utilizzano Google Analytics per monitorare le prestazioni.
        </li>
      </ul>
      <h3>Come gestire i cookie?</h3>
      <p>
        I cookie possono essere gestiti senza troppi sforzi se scegli
        una piattaforma di gestione dei cookie con cui lavorare. Chiamata anche
        "piattaforma di gestione del consenso", un gestore di cookie raccoglie
        il consenso dell'utente e fornisce tutte le informazioni necessarie ai
        visitatori in conformità con le più recenti leggi sulla privacy in tutto
        il mondo, come il Regolamento generale sulla protezione dei dati (GDPR)
        dell'UE.
      </p>
      <p>
        Le informazioni provengono dalla scansione regolare del tuo sito web
        e aggiornando la sua dichiarazione sui cookie e spiegando quali cookie
        sono utilizzati sul tuo sito web. Tenendo a bada queste informazioni, i
        tuoi utenti possono rimanere informati e prendere una decisione saggia
        sui cookie. Pertanto, un gestore di cookie dovrebbe fornire agli utenti
        l'opportunità di disattivare il tracciamento dei cookie in qualsiasi
        momento possibile. Questo è il modo in cui il tuo sito web può rimanere
        conforme al GDPR e al CCPA e tu, se dai il tuo consenso, puoi utilizzare
        le informazioni che il tuo sito web ha monitorato per scopi analitici e
        di personalizzazione.
      </p>
      <p>
        Tuttavia, se non riesci a proteggere una piattaforma di gestione del
        consenso, potresti avere seri problemi per aver raccolto informazioni
        sensibili senza avvisare gli utenti e ricevere il loro consenso.
        Sebbene dipenda dalle leggi e dai regolamenti in cui si trovano il tuo
        sito web e i suoi utenti, la maggior parte delle volte i siti web hanno
        bisogno di un gestore di cookie se vogliono tracciare gli utenti ed
        evitare multe per violazioni della legge sui dati.
      </p>
    </div>
  );
}
