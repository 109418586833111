import PageHeading from "./PageHeading";
import "../../../css/LettiKids.css";
import Products2 from "./Products2";
import useFetch from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import LanguageContext from "../../../context/language-context";

export default function LettiFrontPage2(props) {
  const languageCtx = useContext(LanguageContext);

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/${props.link}?locale=${languageCtx.languageData}&populate[Product_name_and_image][populate][0]=Front_Image&populate[Banner_Image][populate][1]=Banner_Image`
  );

  localStorage.setItem("lettilink", props.link);

  const { slug } = useParams();
  if (loading) return <p>Loading...</p>;
  const { data: details, meta } = data;

  // Find the details object with the matching slug
  const matchingDetails = details.find((item) => {
    return item.attributes.url === slug.toLowerCase();
  });
  if (!matchingDetails) {
    return <p>No matching details found for the provided slug.</p>;
  }

  return (
    <div className="main-content">
      <PageHeading
        image={matchingDetails.attributes.Banner_Image.data.attributes.url}
        content={matchingDetails.attributes.Banner_Text}
      />
      <Products2
        products={matchingDetails.attributes.Product_name_and_image}
        slug={matchingDetails.attributes.url}
        link={props.link}
      />
    </div>
  );
}
