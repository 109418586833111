import React, { useState, useEffect, useContext } from "react";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import ApiContext from "../../../context/api-context";
import useFetch from "../../../hooks/useFetch";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useMediaQuery } from "react-responsive";
import { Carousel } from "react-responsive-carousel";
import Popup from "../../UI/Popup";
import { NavLink } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import "../../../css/LettiInner.css";
import LanguageContext from "../../../context/language-context";
export default function SedieInner(props) {
  const [isProductContentFixed, setIsProductContentFixed] = useState(true);
  const isBigScreen = useMediaQuery({ query: "(min-width: 1100px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1100px)" });
  const { slug, product_name } = useParams();
  const ctx = useContext(ApiContext);
  const languageCtx = useContext(LanguageContext);
  let links;
  // Get the full pathname
  const fullPath = window.location.pathname;

  // Remove leading slash if present
  const pathWithoutLeadingSlash = fullPath.startsWith("/")
    ? fullPath.substring(1)
    : fullPath;

  // Split the path by slashes
  const pathSegments = pathWithoutLeadingSlash.split("/");

  // Get the first segment
  const firstPathSegment = pathSegments[0];
  if (props.link) {
    // Use props.link if it is available
    links = props.link;
  } else {
    ctx.forEach((element) => {
      if (element.name === firstPathSegment) links = element.link;
    });
  }
  var pagename;
  const CustomPropsBreadcrumb = ({ someProp }) => <span>/ {someProp}</span>;

  const routes = [
    {
      path: "product_name/:locale/",
      breadcrumb: CustomPropsBreadcrumb,
      props: { someProp: pagename },
    },
  ];
  const breadcrumbs = useBreadcrumbs(routes);

  const uniqueBreadcrumbs = breadcrumbs;

  breadcrumbs.forEach(({ match, breadcrumb }, index) => {
    const isDuplicate =
      index > 0 &&
      breadcrumb.props.children ===
        breadcrumbs[index - 1].breadcrumb.props.children;

    if (isDuplicate) {
      uniqueBreadcrumbs.splice(index - 1, 1);
    }
  });
  useEffect(() => {
    const handleScroll = () => {
      const bedImagesBottom = document
        .querySelector(".bed-images")
        .getBoundingClientRect().bottom;
      const windowHeight = window.innerHeight;

      if (bedImagesBottom <= windowHeight) {
        setIsProductContentFixed(false);
      } else {
        setIsProductContentFixed(true);
      }
    };

    if (isBigScreen) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isBigScreen]);

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/${links}?locale=${languageCtx.languageData}&populate[0]=Product_name_and_Image&populate[1]=Product_name_and_Image.Innerpage_details.Images&populate[2]=Product_name_and_Image.Piedini.Image&populate[3]=Product_name_and_Image.Popup.Icon&populate[4]=Product_name_and_Image.Popup.Pdf`
  );

  if (loading) return <p>Loading...</p>;

  const { data: details, meta } = data;

  const matchingDetails = details.find((item) => {
    return item.attributes.url === slug;
  });
  let selectedProducts;
  if (matchingDetails) {
    if (Array.isArray(matchingDetails.attributes.Product_name_and_Image)) {
      selectedProducts = matchingDetails.attributes.Product_name_and_Image.find(
        (item) => {
          return item.product_name.toLowerCase() === product_name.toLowerCase();
        }
      );
    } else {
      selectedProducts = matchingDetails.attributes.Product_name_and_Image;
    }
  }

  if (!selectedProducts) {
    return <p>Product not found</p>;
  }

  const content = selectedProducts.Innerpage_details.Product_description;

  return (
    <>
      <div className="breadcrumb-wrapper">
        {uniqueBreadcrumbs.map(({ match, breadcrumb }, index) => {
          if (uniqueBreadcrumbs.length == 4) {
            if (index == 1) {
              return null;
            }
          }
          const isLastBreadcrumb = index === breadcrumbs.length - 1;
          const isSecondToLastBreadcrumb = index === breadcrumbs.length - 2;

          return (
            <React.Fragment key={match.pathname}>
              <NavLink to={match.pathname}>
                {!isSecondToLastBreadcrumb
                  ? decodeURIComponent(breadcrumb.props.children)
                  : matchingDetails.attributes.Page_Name}
              </NavLink>
              {!isLastBreadcrumb && " / "}
              {/* Render a slash after each breadcrumb except the last one */}
            </React.Fragment>
          );
        })}
      </div>
      <div className="whole-page">
        <div className="bed-images">
          {!isTabletOrMobile ? (
            // Render the content for tablet or mobile devices
            selectedProducts.Innerpage_details.Images.data.map(
              (item, index) => (
                <div className="single-bed" key={index}>
                  <img
                    src={`https://spagnoladmin.spagnolmobili.it${item.attributes.url}`}
                    alt="Bed"
                  />
                </div>
              )
            )
          ) : (
            // Render a carousel for other devices
            <Carousel
              showThumbs={false}
              autoPlay={true}
              infiniteLoop={true}
              showIndicators={false}
            >
              {selectedProducts.Innerpage_details.Images.data.map(
                (item, index) => (
                  <img
                    key={index}
                    src={`https://spagnoladmin.spagnolmobili.it${item.attributes.url}`}
                    alt="Bed"
                  />
                )
              )}
            </Carousel>
          )}
        </div>
        <div
          className={`product-content ${isProductContentFixed ? "fixed" : ""}`}
        >
          <BlocksRenderer content={content} />
        </div>
        {selectedProducts.Popup && <Popup products={selectedProducts.Popup} />}
      </div>
    </>
  );
}
