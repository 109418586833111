import classes from "../../../css/FrontPage.module.css";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
export default function PageHeading(props) {
  const content = props.pageheading;
  return (
    <div className={classes["amadi-head"]}>
      <BlocksRenderer content={content} />
    </div>
  );
}
