import React, { useState } from "react";
import NewsItems from "./Newsitem";
import "../../../css/News.css";
import useFetch from "../../../hooks/useFetch";
import { useContext } from "react";
import LanguageContext from "../../../context/language-context";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { IoIosArrowDown } from "react-icons/io";

export default function News() {
  const languageCtx = useContext(LanguageContext);
  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/newss?locale=${languageCtx.languageData}&populate[0]=Page_Heading&populate[1]=News_Items&populate[2]=News_Items.Front_Image&populate[3]=News_Items.Inner_Images&populate[4]=News_Items.Banner_Image`
  );

  const [displayedItems, setDisplayedItems] = useState(5);

  const handleReadMore = () => {
    setDisplayedItems((prevCount) => prevCount + 5);
  };

  if (loading) return <p>Loading...</p>;
  const { data: details, meta } = data;
  const content = details[0].attributes.Page_Heading;

  return (
    <div className="news-main">
      <section className="intro maxWidth" data-scroll-section="">
        <div>
          <BlocksRenderer content={content} />
        </div>
      </section>
      <section className="lista maxWidth" data-scroll-section="">
        <div>
          <ul className="news-content">
            {details[0].attributes.News_Items.slice(0, displayedItems).map(
              (item, index) => (
                <NewsItems
                  key={index}
                  content={item.Description}
                  title={item.Title}
                  img={item.Front_Image.data.attributes.url}
                />
              )
            )}
          </ul>
          {details[0].attributes.News_Items.length > displayedItems && (
            <a className="show-more" onClick={handleReadMore}>
              <span> Altre</span> <IoIosArrowDown />
            </a>
          )}
        </div>
      </section>
    </div>
  );
}
