import PageHeading from "./PageHeading";
import ItaliaText from "./ItaliaText";
import Agents from "./Agents";
import WorldAgents from "./WorldAgents";
import useFetch from "../../hooks/useFetch";
import DealerInformation from "./DealerInformation";
import { useState } from "react";
import WorldInformation from "./WorldInformation";
export default function Rivenditori() {
  const [open, setOpen] = useState(false);
  const [openWorld, setOpenWorld] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleOptionChange = (selectedValue) => {
    setSelectedOption(selectedValue); // Update the selected option in parent component
  };
  const handleWorldChange = (selectedValue) => {
    setSelectedCountry(selectedValue); // Update the selected option in parent component
  };
  const searchAgentHandler = () => {
    setOpen(true);
  };
  const searchWorldHandler = () => {
    setOpenWorld(true);
  };

  const closeAgentHandler = () => {
    setOpen(false);
    setOpenWorld(false);
  };

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/rivenditoris?populate[0]=Dealer_information&populate[1]=Banner_image&populate[2]=Middle_image&populate[3]=World_Dealer`
  );

  if (loading) return <p>Loading...</p>;
  const selectedAgent = data.data[0].attributes.Dealer_information.find(
    (item) => item.value === selectedOption
  );
  const show = open || openWorld;
  return (
    <div className="rivenditori-main">
      {!show && (
        <>
          <PageHeading
            image={data.data[0].attributes.Banner_image.data.attributes.url}
          />
          <ItaliaText />
          <Agents
            agents={data.data[0].attributes.Dealer_information}
            searchAgentHandler={searchAgentHandler}
            handleOptionChange={handleOptionChange}
          />
          <PageHeading
            image={data.data[0].attributes.Middle_image.data.attributes.url}
          />
          <WorldAgents
            searchAgentHandler={searchWorldHandler}
            handleOptionChange={handleWorldChange}
          />
        </>
      )}
      {open && (
        <DealerInformation
          closeAgentHandler={closeAgentHandler}
          name={selectedAgent.name}
          email={selectedAgent.email}
          province={selectedAgent.region}
          phonenumber={selectedAgent.number}
        />
      )}
      {openWorld && (
        <WorldInformation
          closeAgentHandler={closeAgentHandler}
          name={data.data[0].attributes.World_Dealer[0].Name}
          email={data.data[0].attributes.World_Dealer[0].email}
          phonenumber={data.data[0].attributes.World_Dealer[0].Number}
          country={selectedCountry}
        />
      )}
    </div>
  );
}
