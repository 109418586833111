import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import ProductList from "./ProductList";
import useFetch from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import "../../../css/InnerPage.css";
import { useContext } from "react";
import ApiContext from "../../../context/api-context";
import LanguageContext from "../../../context/language-context";
import { NavLink } from "react-router-dom";
import Popup from "../../UI/Popup";

export default function InnerPage2(props) {
  const { slug } = useParams();
  const ctx = useContext(ApiContext);
  const languageCtx = useContext(LanguageContext);

  let links;
  if (props.link) {
    // Use props.link if it is available
    links = props.link;
  } else {
    // Retrieve item from localStorage if props.link is not available
    links = localStorage.getItem("link");
  }
  const CustomPropsBreadcrumb = ({ someProp }) => <span>/ {someProp}</span>;
  const routes = [
    {
      path: "product_name/:locale/",
      breadcrumb: CustomPropsBreadcrumb,
      props: { someProp: pagename },
    },
  ];
  const breadcrumbs = useBreadcrumbs(routes);
  const uniqueArray1 = breadcrumbs.filter((value, index, self) => {
    // Find the index of the first occurrence of the current item's name in the array
    const firstIndex = self.findIndex(
      (item) =>
        item.breadcrumb.props.children === value.breadcrumb.props.children
    );
    // Return true only if the current index is the same as the index of the first occurrence
    return index === firstIndex;
  });

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/${links}?locale=${languageCtx.languageData}&populate[0]=Product_name_and_Image&populate[1]=Product_name_and_Image.Inner_page_details.bigimage&populate[2]=Product_name_and_Image.Inner_page_details.mediumimage&populate[3]=Product_name_and_Image.Inner_page_details.smallimage&populate[4]=Product_name_and_Image.Popup.Icon&populate[5]=Product_name_and_Image.Popup.Pdf`
  );
  if (loading) return <p>Loading...</p>;

  const { data: details, meta } = data;

  var selectedProducts;
  var pagename;

  selectedProducts = details[0].attributes.Product_name_and_Image;
  if (!selectedProducts) {
    return <p>Product not found</p>;
  }

  return (
    <div className="main-inner">
      <div className="breadcrumb-wrapper">
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          // Skip rendering the second path
          if (index === 1) {
            return null;
          }

          const isLastBreadcrumb = index === breadcrumbs.length - 1;

          return (
            <React.Fragment key={match.pathname}>
              <NavLink to={match.pathname}>
                {!isLastBreadcrumb
                  ? decodeURIComponent(breadcrumb.props.children)
                  : details[0].attributes.Page_Name}
              </NavLink>
              {!isLastBreadcrumb && " / "}{" "}
              {/* Render a slash after each breadcrumb except the last one */}
            </React.Fragment>
          );
        })}
      </div>
      <ProductList products={selectedProducts.Inner_page_details} />
      <Popup products={selectedProducts.Popup} />
    </div>
  );
}
