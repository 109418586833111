import "../../css/Dropdown.css";
import { useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import LanguageContext from "../../context/language-context";
export default function DropDown() {
  const [showsub, SetShowSub] = useState(false);
  const [showLettiSub, SetShowLettiSub] = useState(false);
  const [showLettiKidSub, SetShowLettiKidSub] = useState(false);
  const [showAcastelloSub, SetShowAcastelloSub] = useState(false);
  const translations = {
    en: {
      notte: "SLEEPING AREA",
      giorno: "LIVING AREA",
      kids: "KIDS",
      complementi: "COMPLEMENTS",
      battenti: "Hinged wardrobes",
      letti: "Beds",
      acastello: "Stacked and bunk beds",
    },
    it: {
      notte: "ZONA NOTTE",
      giorno: "ZONA GIORNO",
      kids: "KIDS",
      complementi: "COMPLEMENTI",
      battenti: "Armadi Battenti",
      letti: "Letti",
      acastello: "Letti sovrapposti e a castello",
    },
    es: {
      notte: "ZONA PARA DORMIR",
      giorno: "SALA DE ESTAR",
      kids: "NIÑOS",
      complementi: "COMPLEMENTOS",
      battenti: "Armarios abatibles",
      letti: "Camas",
      acastello: "Camas apiladas y literas",
    },
    de: {
      notte: "SCHLAFBEREICH",
      giorno: "WOHNBEREICH",
      kids: "KINDER",
      complementi: "ERGÄNZUNGEN",
      battenti: "Aufklappbare Kleiderschränke",
      letti: "Betten",
      acastello: "Stapel- und Etagenbetten",
    },
    fr: {
      notte: "COIN NUIT",
      giorno: "SALON",
      kids: "ENFANTS",
      complementi: "COMPLÉMENTS",
      battenti: "Armoires battantes",
      letti: "Des lits",
      acastello: "Lits empilés et superposés",
    },
  };
  const ToggleSubMenu = (event) => {
    event.preventDefault();
    SetShowSub((prev) => !prev);
  };
  const ToggleLettiSubMenu = (event) => {
    event.preventDefault();
    SetShowLettiSub((prev) => !prev);
  };
  const ToggleLettiKidSubMenu = (event) => {
    event.preventDefault();
    SetShowLettiKidSub((prev) => !prev);
  };
  const ToggleAcastelloSubMenu = (event) => {
    event.preventDefault();
    SetShowAcastelloSub((prev) => !prev);
  };

  const languageCtx = useContext(LanguageContext);
  const {
    loading,
    error,
    data: battenti,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/armadi-battentis?locale=${languageCtx.languageData}&populate=*`
  );
  const {
    loading: loading23,
    error: error23,
    data: libro,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/armadi-a-libros?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading1,
    error: error1,
    data: scorevolli,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/armadi-scorevollis?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading2,
    error: error2,
    data: letti,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/lettis?locale=${languageCtx.languageData}&populate=*`
  );
  const {
    loading: loading3,
    error: error3,
    data: comoecomodini,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/como-e-comodinis?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading4,
    error: error4,
    data: cabinearmadio,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/cabine-armadios?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading5,
    error: error5,
    data: chisurescorevolli,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/chisurescorevollis?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading6,
    error: error6,
    data: scomparsa,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/scomparsas?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading7,
    error: error7,
    data: servizio,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/servizios?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading8,
    error: error8,
    data: libreria,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/librerias?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading9,
    error: error9,
    data: contenitori,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/contenitoris?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading10,
    error: error10,
    data: madie,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/madies?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading11,
    error: error11,
    data: camrette,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/camrettes?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading12,
    error: error12,
    data: lettikids,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/letti-kids?locale=${languageCtx.languageData}&populate=*`
  );
  const {
    loading: loading13,
    error: error13,
    data: acastello,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/letti-sovrapposti-e-acastellos?locale=${languageCtx.languageData}&populate=*`
  );
  const {
    loading: loading14,
    error: error14,
    data: scrittoi,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/scritois?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading15,
    error: error15,
    data: baldacchino,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/letto-baldacchinos?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading16,
    error: error16,
    data: vega,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/letto-vegas?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading17,
    error: error17,
    data: comodini,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/como-e-comodini-kids?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading18,
    error: error18,
    data: boiserie,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/boiseries?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading19,
    error: error19,
    data: elementi,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/elementi-in-metallos?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading20,
    error: error20,
    data: sedie,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/sedeie-e-poltroncines?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading21,
    error: error21,
    data: tavoli,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/tavoli-e-tavolinis?locale=${languageCtx.languageData}`
  );
  const {
    loading: loading22,
    error: error22,
    data: giorno,
  } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/elementi-a-giornos?locale=${languageCtx.languageData}`
  );
  if (
    loading23 ||
    loading22 ||
    loading21 ||
    loading20 ||
    loading19 ||
    loading18 ||
    loading17 ||
    loading16 ||
    loading15 ||
    loading14 ||
    loading13 ||
    loading12 ||
    loading11 ||
    loading10 ||
    loading9 ||
    loading8 ||
    loading7 ||
    loading6 ||
    loading5 ||
    loading4 ||
    loading3 ||
    loading2 ||
    loading1 ||
    loading
  )
    return <p>Loading...</p>;

  return (
    <div className="header">
      <ul className="menu-submenu menu-title-1">
        <li id="submenu-menu" className="menu-submenu-item row">
          <ul id="menu-div1" className="sec-20">
            <li>
              <a id="div-name" className="notte" href="#">
                {translations[languageCtx.languageData].notte}
              </a>
              <ul className="single-submenu-item-menu-list">
                <li>
                  <a
                    onClick={ToggleSubMenu}
                    id="amadi-1"
                    className="battenti underline-hover-effect"
                    href="#"
                  >
                    {translations[languageCtx.languageData].battenti}
                  </a>
                  <ul
                    id="submenu-1"
                    className={` ${showsub ? "show" : "hide"}`}
                  >
                    {battenti.data.map((item, index) => {
                      let productDescription = item.attributes.Page_Name;

                      if (productDescription.includes("-")) {
                        productDescription = productDescription
                          .split("-")
                          .join(" ");
                      }

                      // Determine the route path and component name based on the condition
                      let routePath;
                      if (item.attributes.Product_name_and_Image.length === 1) {
                        // If there's only one element in the array, use a different component
                        routePath = `armadibattenti/${item.attributes.url}/${item.attributes.url}`;
                      } else {
                        // Otherwise, use the default component
                        routePath = `armadibattenti/${item.attributes.url}`;
                      }

                      return (
                        <li key={index}>
                          <Link
                            to={routePath}
                            className="ardeco"
                            onClick={() =>
                              localStorage.setItem("link", "armadi-battentis")
                            }
                          >
                            {productDescription}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  {scorevolli.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`armadiscorevolli/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {libro.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`armadialibro/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  <a
                    onClick={ToggleLettiSubMenu}
                    id="submenu-main"
                    href="#"
                    className="letti underline-hover-effect"
                  >
                    {translations[languageCtx.languageData].letti}
                  </a>
                  <ul
                    id="submenu-2"
                    className={`${showLettiSub ? "show" : "hide"}`}
                  >
                    {letti.data.map((item, index) => {
                      let productDescription = item.attributes.Page_Name;

                      if (productDescription.includes(`-`)) {
                        productDescription = productDescription
                          .split(`-`)
                          .join(` `);
                      }
                      return (
                        <li key={index}>
                          <Link
                            to={`letti/${item.attributes.url}`}
                            className="lettiimbotiti"
                          >
                            {productDescription}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  {comoecomodini.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`comòecomodini/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {cabinearmadio.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`cabinearmadio/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {chisurescorevolli.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`chisurescorevolli/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {scomparsa.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`scomparsa/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {servizio.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`servizio/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
              </ul>
            </li>
          </ul>
          <ul id="menu-div2" className="sec-20">
            <li>
              <a id="div-name" href="#" className="giorno">
                {translations[languageCtx.languageData].giorno}
              </a>
              <ul className="single-submenu-item-menu-list">
                <li>
                  {libreria.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`libreria_/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {contenitori.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`contenitori/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {madie.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`madie/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
              </ul>
            </li>
          </ul>
          <ul id="menu-div3" className="sec-20">
            <li>
              <a id="div-name" href="#" className="kids">
                {translations[languageCtx.languageData].kids}
              </a>
              <ul className="single-submenu-item-menu-list">
                <li>
                  {camrette.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`camrette-/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>

                <li>
                  <a
                    onClick={ToggleLettiKidSubMenu}
                    id="submenu-main"
                    href="#"
                    className="lettikids underline-hover-effect"
                  >
                    {translations[languageCtx.languageData].letti}
                  </a>
                  <ul
                    id="submenu-3"
                    className={`${showLettiKidSub ? "show" : "hide"}`}
                  >
                    {lettikids.data.map((item, index) => {
                      let productDescription = item.attributes.Page_Name;

                      if (productDescription.includes(`-`)) {
                        productDescription = productDescription
                          .split(`-`)
                          .join(` `);
                      }
                      return (
                        <li key={index}>
                          <Link
                            to={`lettikids/${item.attributes.url}`}
                            className="lettiimbotiti"
                          >
                            {productDescription}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <a
                    onClick={ToggleAcastelloSubMenu}
                    className="mycastello acastello underline-hover-effect"
                    id="submenu-main"
                    href="#"
                  >
                    {translations[languageCtx.languageData].acastello}
                  </a>
                  <ul
                    id="submenu-4"
                    className={`${showAcastelloSub ? "show" : "hide"}`}
                  >
                    {acastello.data.map((item, index) => {
                      let productDescription = item.attributes.Page_Name;

                      if (productDescription.includes(`-`)) {
                        productDescription = productDescription
                          .split(`-`)
                          .join(` `);
                      }
                      return (
                        <li key={index}>
                          <Link
                            to={`lettiacastello/${item.attributes.url}`}
                            className="lettiimbotiti"
                          >
                            {productDescription}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  {scrittoi.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`scrittoi/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {baldacchino.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`lettobaldacchino/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {vega.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`lettovega/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {comodini.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`kids/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
              </ul>
            </li>
          </ul>
          <ul id="menu-div4" className="sec-20">
            <li>
              <a id="div-name" href="#" className="complementi">
                {translations[languageCtx.languageData].complementi}
              </a>
              <ul className="single-submenu-item-menu-list">
                <li>
                  {boiserie.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`boiserie_/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {elementi.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`elementiinmetallo/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {sedie.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`sedie/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {tavoli.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`tavoli/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
                <li>
                  {giorno.data.map((item, index) => {
                    let productDescription = item.attributes.Page_Name;

                    if (productDescription.includes(`-`)) {
                      productDescription = productDescription
                        .split(`-`)
                        .join(` `);
                    }
                    return (
                      <Link
                        id="submenu-main"
                        to={`elementi/${item.attributes.url}`}
                        className="ardeco underline-hover-effect"
                        key={index}
                      >
                        {productDescription}
                      </Link>
                    );
                  })}
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
