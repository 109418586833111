import PageHeading from "./PageHeading";
import classes from "../../../css/FrontPage.module.css";
import Products from "./Products";
import useFetch from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import LanguageContext from "../../../context/language-context";

export default function FrontPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    // Scrolls to the top-left corner of the page
  }, []);
  if (props.link) {
    localStorage.setItem("link", props.link);
  }

  const links = props.link || localStorage.getItem("link");
  const languageCtx = useContext(LanguageContext);

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/${links}?locale=${languageCtx.languageData}&populate[Product_name_and_Image][populate][0]=ProductImage`
  );
  // Update the URL when language changes
  const { slug } = useParams();
  if (loading) return <p>Loading...</p>;
  const { data: details, meta } = data;
  const matchingDetails = details.find((item) => {
    return item.attributes.url === slug;
  });
  if (!matchingDetails) {
    return <p>No matching details found for the provided slug.</p>;
  }
  // const matchingDetails = details;
  return (
    <div className={classes["main-content"]}>
      <PageHeading pageheading={matchingDetails.attributes.Page_Heading} />
      <Products
        products={matchingDetails.attributes.Product_name_and_Image}
        page_name={matchingDetails.attributes.Page_Name}
        locale={matchingDetails.attributes.locale}
        link={props.link}
      />
    </div>
  );
}
