export default function IndexVideo(props) {
  return (
    <div id="slideImg20">
      <video
        width="100%"
        height="100%"
        autoPlay
        muted
        playsInline
        loop
        id="myVideo"
      >
        <source
          id="myVideoID"
          src={`https://spagnoladmin.spagnolmobili.it${props.video}`}
          type="video/mp4"
        />
      </video>
    </div>
  );
}
