import { useState } from "react";
export default function SingleProduct(props) {
  return (
    <div className="product-box">
      <div className="bigImage">
        <a
          className="firstImglink"
          href="#"
          data-fancybox="gallery"
          onClick={props.onClickHandler}
        >
          <img
            className="firstBigImg"
            src={`https://spagnoladmin.spagnolmobili.it${props.bigimage}`}
            alt=""
          />
        </a>
      </div>
      <div className="bottom-part">
        <div className="mediumImg flex-item">
          <a href="#" onClick={props.onClickHandler}>
            <img
              className="firstMidImg"
              src={`https://spagnoladmin.spagnolmobili.it${props.mediumimage}`}
              alt=""
            />
          </a>
        </div>
        <div className="column flex-item">
          <div className="smallImg">
            <a href="#" onClick={props.onClickHandler}>
              <img
                className="firstSmallImg"
                src={`https://spagnoladmin.spagnolmobili.it${props.smallimage}`}
                alt=""
              />
            </a>
          </div>

          <div className="text-box">
            <p className="smalltext italian">{props.productdescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
