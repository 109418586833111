import { Link } from "react-router-dom";
import classes from "../../../css/FrontPage2.module.css";
export default function SingleProduct(props) {
  return (
    <div className={classes.imageChild}>
      <Link
        to={`./${props.product_name.toLowerCase()}`}
        className={classes["img-link"]}
      >
        <div className={classes.wrapperImg}>
          <img
            className={classes["boxImg"]}
            src={`https://spagnoladmin.spagnolmobili.it${props.image}`}
            alt="armadi ad anta battente design"
          />
        </div>

        <div id="col-h" className="sec-12">
          <h3 className={classes.firstImgcontent}>{props.productname}</h3>
        </div>
      </Link>
    </div>
  );
}
