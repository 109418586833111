import Socials from "./Socials";
import "../../css/Header.css";
import { useContext, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import LanguageContext from "../../context/language-context";
import LanguageMenu from "./LanguageMenu";

export default function NewHeader(props) {
  const languageCtx = useContext(LanguageContext);
  const [activeMenu, setActiveMenu] = useState("");
  const location = useLocation();
  const headerRef = useRef(null);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isNotHome, setIsNotHome] = useState(location.pathname !== "/");

  useEffect(() => {
    // Check if the route is not home
    setIsNotHome(location.pathname !== "/");
    if (location.pathname !== "/") {
      const activeMenuFromStorage = localStorage.getItem("activeMenu");
      if (activeMenuFromStorage) {
        setActiveMenu(activeMenuFromStorage);
      }
    }
  }, [location]);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (headerRef.current) {
      if (scrollY < lastScrollY) {
        // Scrolling up
        headerRef.current.style.top = "0px";
      }

      // Set isScrolling to true when scrolling
      setIsScrolling(scrollY !== 0);
    }

    setLastScrollY(scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    localStorage.setItem("activeMenu", menu);
  };

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/headers?locale=${languageCtx.languageData}&populate[0]=Logo&populate[1]=White_Logo`
  );

  if (loading) return <p>Loading...</p>;
  const black = data.data[0].attributes.Logo.data.attributes.url;
  const white = data.data[0].attributes.White_Logo.data.attributes.url;

  return (
    <>
      <header>
        <div
          className={`Desktopview ${
            props.showdropdown || isScrolling || isNotHome ? "has-bg" : ""
          }`}
          ref={headerRef}
        >
          <div className="container-fluid">
            <div className="navbar-brand">
              <a href="/" onClick={() => handleMenuClick("home")}>
                <img
                  loading="lazy"
                  src={
                    props.showdropdown || isScrolling || isNotHome
                      ? `https://spagnoladmin.spagnolmobili.it${black}`
                      : `https://spagnoladmin.spagnolmobili.it${white}`
                  }
                  className="isBlack1"
                />
              </a>
            </div>
            <div className="left-most">
              <div className="navbar-brand">
                <LanguageMenu />
              </div>
              <Socials
                area={data.data[0].attributes.Area_Riservata}
                facebook={data.data[0].attributes.Facebook}
                instagram={data.data[0].attributes.Instagram}
                x={data.data[0].attributes.X}
                pinterest={data.data[0].attributes.Pinterest}
                youtube={data.data[0].attributes.Youtube}
              />
            </div>

            <div className="right-most">
              <ul className="menulist">
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      props.ToggleDropDown(e);
                      handleMenuClick("prodotti");
                    }}
                    className={`menuOpen menuitem prodotti ${
                      activeMenu === "prodotti" ? "active-menu" : ""
                    }`}
                  >
                    {data.data[0].attributes.Prodotti}
                  </a>
                </li>
                <li>
                  <a
                    href="/cataloghi"
                    onClick={() => handleMenuClick("cataloghi")}
                    className={`menuitem cataloghi ${
                      activeMenu === "cataloghi" ? "active-menu" : ""
                    }`}
                  >
                    {data.data[0].attributes.Cataloghi}
                  </a>
                </li>
                <li>
                  <a
                    href="/azienda"
                    onClick={() => handleMenuClick("azienda")}
                    className={`menuitem contatti ${
                      activeMenu === "azienda" ? "active-menu" : ""
                    }`}
                  >
                    {data.data[0].attributes.Azienda}
                  </a>
                </li>
                <li>
                  <a
                    href="/rivenditori"
                    onClick={() => handleMenuClick("rivenditori")}
                    className={`menuitem rivenditory ${
                      activeMenu === "rivenditori" ? "active-menu" : ""
                    }`}
                  >
                    {data.data[0].attributes.Rivenditori}
                  </a>
                </li>
                <li>
                  <a
                    href="/news"
                    onClick={() => handleMenuClick("news")}
                    className={`menuitem contatti ${
                      activeMenu === "news" ? "active-menu" : ""
                    }`}
                  >
                    {data.data[0].attributes.News}
                  </a>
                </li>
                <li>
                  <a
                    href="/contatti"
                    onClick={() => handleMenuClick("contatti")}
                    className={`menuitem contatti ${
                      activeMenu === "contatti" ? "active-menu" : ""
                    }`}
                  >
                    {data.data[0].attributes.Contatti}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
