import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { useContext } from "react";
import LanguageContext from "../../context/language-context";
export default function DealerInformation(props) {
  const languageCtx = useContext(LanguageContext);
  const translations = {
    en: {
      paragraph: "Here are the Spagnolmobili dealers in",
      region: "Italy",
      agents: "Agents",
    },
    fr: {
      paragraph: "Voici les revendeurs Spagnolmobili en",
      region: "Italie",
      agents: "Agents",
    },
    it: {
      paragraph: "Ecco i rivenditori Spagnolmobili in",
      region: "Italia",
      agents: "Agenti",
    },
    de: {
      paragraph: "Hier sind die Spagnolmobili-Händler in",
      region: "Italien",
      agents: "Agenten",
    },
    es: {
      paragraph: "Aquí están los distribuidores de Spagnolmobili en",
      region: "Italia",
      agents: "Agentes",
    },
  };
  return (
    <div className="chosenDealer">
      <h4 className="paragraph-3 align-center">
        {translations[languageCtx.languageData].paragraph}
        <br />
        <span className="state italian">
          {translations[languageCtx.languageData].region} ({props.province})
        </span>
        .
      </h4>
      <div className="agent-info">
        <div className="agent">
          <p className="heading-3">
            {translations[languageCtx.languageData].agents}
          </p>
          <p id="dealerName">{props.name}</p>
        </div>
        <div className="email">
          <div className="emailtag">
            <a
              href={`mailto:${props.email}`}
              className="emailStore nvtk-link"
              data-url="source=email"
            >
              <FaEnvelope />
            </a>
          </div>
          <div className="emailaddress">
            <a
              className="emailStore nvtk-link"
              href={`mailto:${props.email}`}
              data-url="source=email"
            >
              {props.email}
            </a>
          </div>
        </div>
        <div className="phone-number">
          <a
            href={`tel:${props.phonenumber}`}
            className="phoneStore nvtk-link"
            data-url="source=phone"
          >
            <FaPhoneAlt />
          </a>
          <div className="number">
            <a
              className="emailStore nvtk-link"
              href={`tel:${props.phonenumber}`}
              data-url=""
            >
              {props.phonenumber}
            </a>
          </div>
        </div>
      </div>
      <button id="close" onClick={props.closeAgentHandler}>
        CLOSE
      </button>
    </div>
  );
}
