import "../../../css/catalogh.css";
import CataloghList from "./CatalogList";
import useFetch from "../../../hooks/useFetch";
import { useContext } from "react";
import LanguageContext from "../../../context/language-context";
import { Link, useLocation } from "react-router-dom";

export default function Cataloghi() {
  const location = useLocation();
  const languageCtx = useContext(LanguageContext);

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/cataloghis?locale=${languageCtx.languageData}&populate[CatalogItem][populate][0]=catalogimage&populate[CatalogItem][populate][1]=catalogpdf`
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const { data: details, meta } = data;

  const getClassName = (path) => {
    return location.pathname === path
      ? "underline-hover-effect active-catalog"
      : "underline-hover-effect";
  };

  return (
    <div className="catalog-main">
      <div id="scarica-div">
        <h3 className="heading-1 schead">{details[0].attributes.Heading}</h3>
      </div>
      <ul className="d-flex catlog-link">
        <li className={getClassName("/cataloghi")}>
          <Link to="/cataloghi">{details[0].attributes.Catalog}</Link>
        </li>
        <li className={getClassName("/scheda-prodotti")}>
          <Link to="/scheda-prodotti">
            {details[0].attributes.Scheda_Technica}
          </Link>
        </li>
        <li className={getClassName("/certificazioni")}>
          <Link to="/certificazioni">
            {details[0].attributes.Certificazioni}
          </Link>
        </li>
      </ul>
      <CataloghList products={data.data[0].attributes.CatalogItem} />
    </div>
  );
}
