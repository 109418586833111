import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import LanguageContext from "../context/language-context";
const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const languageCtx = useContext(LanguageContext);
  const { slug } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const res = await fetch(url);
        const json = await res.json();

        setData(json);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url, languageCtx.languageData, slug]);

  return { loading, error, data };
};

export default useFetch;
