import {
  FaFacebookF,
  FaInstagram,
  FaXTwitter,
  FaPinterest,
  FaYoutube,
} from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";

export default function FooterSocials(props) {
  return (
    <div className="socialsf">
      <ul>
        <li>
          <a className="icon facebook" href={props.facebook}>
            <FaFacebookF />
          </a>
        </li>
        <li>
          <a className="icon instagram" href={props.instagram}>
            <FaInstagram />
          </a>
        </li>
        <li>
          <a className="icon twitter" href={props.x}>
            <FaXTwitter />
          </a>
        </li>
        <li>
          <a className="icon pinterest" href={props.pinterest}>
            <FaPinterest />
          </a>
        </li>
        <li>
          <a className="icon pinterest" href={props.youtube}>
            <FaYoutube />
          </a>
        </li>
      </ul>
    </div>
  );
}
