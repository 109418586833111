import SingleProduct from "./Singleproduct";
export default function Products(props) {
  return (
    <div className="product-grid">
      {props.products.map((item, index) => {
        let productDescription = item.product_name;

        if (productDescription.includes("-")) {
          productDescription = productDescription.split("-").join(" ");
        }
        if (item.Published == false) {
          return <></>;
        }
        return (
          <SingleProduct
            image={item.Front_Image.data?.attributes.url}
            productname={productDescription}
            product_name={item.product_name}
            key={index}
            slug={props.slug}
            link={props.link}
          />
        );
      })}
    </div>
  );
}
