import { useMediaQuery } from "react-responsive";

export default function PageHeading(props) {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1000px)" });

  return (
    <div
      className="page-headings"
      data-scroll-section
      style={{
        backgroundImage: `url(https://spagnoladmin.spagnolmobili.it${props.img})`,
      }}
    >
      {!isBigScreen && (
        <img src={`https://spagnoladmin.spagnolmobili.it${props.img}`} />
      )}
    </div>
  );
}
