import React, { useEffect, useRef, useState, useContext } from "react";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import LanguageContext from "../../../context/language-context";
import { Link } from "react-router-dom";

export default function NewsItems(props) {
  const languageCtx = useContext(LanguageContext);

  const translations = {
    en: {
      read: "Read more",
    },
    fr: {
      read: "Lire la suite",
    },
    de: {
      read: "Mehr lesen",
    },
    ru: {
      read: "Читать далее",
    },
    es: {
      read: "Leer más",
    },
    it: {
      read: "Leggi tutto",
    },
  };

  const [truncatedContent, setTruncatedContent] = useState(null);
  const contentRef = useRef();

  useEffect(() => {
    if (props.content) {
      const updatedContent = props.content.map((paragraph, index) => {
        if (index === 0 && paragraph.children[0].text.length > 58) {
          return {
            ...paragraph,
            children: [
              {
                ...paragraph.children[0],
                text: paragraph.children[0].text.slice(0, 58),
              },
            ],
          };
        } else {
          return {
            ...paragraph,
            children: [], // Empty array to render no children
          };
        }
      });
      setTruncatedContent(updatedContent);
    }
  }, [props.content]);

  let productTitle = props.title;

  if (productTitle.includes(`-`)) {
    productTitle = productTitle.split(`-`).join(` `);
  }

  return (
    <li className="item">
      <Link to={`/news/${props.title}`}>
        <div className="uk-grid">
          <div className="txt uk-width-1-2@m">
            <h5 className="t-xlarge t-bold">{productTitle}</h5>
            <div className="t-medium t-light" ref={contentRef}>
              {truncatedContent && (
                <BlocksRenderer content={truncatedContent} />
              )}
            </div>
            <span className="read-more">
              <Link to={`/news/${props.title}`}>
                {translations[languageCtx.languageData].read}
              </Link>
            </span>
          </div>
          <div className="wrapperImg">
            <div data-scroll="" data-scroll-speed="-.4" className="is-inview">
              <img
                src={`https://spagnoladmin.spagnolmobili.it${props.img}`}
                alt={props.title}
              />
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
}
