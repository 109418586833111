import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useEffect } from "react";
import ReactGA from "react-ga";
import RootLayout from "./Components/UI/Root";
import Home from "./Components/Pages/Home/Home";
import FrontPage from "./Components/Pages/Front Pages/FrontPage";
import FrontPage2 from "./Components/Pages/Two Image Front Page/FrontPage2";
import LettiFrontPage from "./Components/Pages/Letti Front Page/FrontPage";
import LettiFrontPage2 from "./Components/Pages/Letti Front Page/FrontPage2";
import InnerPage from "./Components/Pages/Inner Page/InnerPage";
import InnerPage2 from "./Components/Pages/Inner Page/InnerPage2";
import LettiInner from "./Components/Pages/Letti Inner/LettiInner";
import LettiInner2 from "./Components/Pages/Letti Inner/LettiInner2";
import SedieFrontPage from "./Components/Pages/Sedie Front Page/FrontPage";
import TavoliFrontPage from "./Components/Pages/Tavoli Front Page/FrontPage";
import SedieInner from "./Components/Pages/Sedie Inner/Sedienner";
import TavoliInner from "./Components/Pages/Tavoli Inner/TavoliInner";
import Cataloghi from "./Components/Pages/Cataloghi/Cataloghi";
import Cataloghi11 from "./Components/Pages/Cataloghi11/Cataloghi11";
import Rivenditori from "./Components/Rivenditori/Rivenditori";
import Contacts from "./Components/Pages/Contatti/Contacts";
import News from "./Components/Pages/News/News";
import NewsInner from "./Components/Pages/News/NewsInner";
import Azienda from "./Components/Pages/Azienda/azienda";
import Cookies from "./Components/Pages/Cookies/cookies";
import Policy from "./Components/Pages/Policy/policy";
import NotFoundComponent from "./Components/NotFound";
import Certificazioni from "./Components/Pages/Certificazioni/Certificazioni";
const TRACKING_ID = "UA-31353302-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      // errorElement: <ErrorPage />,
      id: "root",
      // loader: RootData,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          element: <FrontPage link={"armadi-battentis"} />,
          path: "armadibattenti/:slug",
        },

        {
          element: <FrontPage link={"armadi-scorevollis"} />,
          path: "armadiscorevolli/:slug",
        },
        {
          element: <FrontPage link={"armadi-a-libros"} />,
          path: "armadialibro/:slug",
        },
        {
          element: <FrontPage link={"lettis"} />,
          path: "letti/:slug",
        },
        {
          element: <FrontPage link={"como-e-comodinis"} />,
          path: "comòecomodini/:slug",
        },
        {
          element: <FrontPage2 link={"cabine-armadios"} />,
          path: "cabinearmadio/:slug",
        },
        {
          element: <InnerPage2 link={"chisurescorevollis"} />,
          path: "chisurescorevolli/:slug",
        },
        {
          element: <InnerPage2 link={"scomparsas"} />,
          path: "scomparsa/:slug",
        },
        {
          element: <InnerPage2 link={"servizios"} />,
          path: "servizio/:slug",
        },
        {
          element: <FrontPage link={"librerias"} />,
          path: "libreria_/:slug",
        },
        {
          element: <InnerPage2 link={"contenitoris"} />,
          path: "contenitori/:slug",
        },
        {
          element: <FrontPage link={"madies"} />,
          path: "madie/:slug",
        },
        {
          element: <FrontPage2 link={"camrettes"} />,
          path: "camrette-/:slug",
        },
        {
          element: <InnerPage2 link={"scritois"} />,
          path: "scrittoi/:slug",
        },
        {
          element: <InnerPage2 link={"letto-baldacchinos"} />,
          path: "lettobaldacchino/:slug",
        },
        {
          element: <InnerPage2 link={"letto-vegas"} />,
          path: "lettovega/:slug",
        },
        {
          element: <FrontPage link={"como-e-comodini-kids"} />,
          path: "kids/:slug",
        },
        {
          element: <FrontPage2 link={"boiseries"} />,
          path: "boiserie_/:slug",
        },
        {
          element: <FrontPage2 link={"elementi-in-metallos"} />,
          path: "elementiinmetallo/:slug",
        },
        {
          element: <InnerPage2 link={"elementi-a-giornos"} />,
          path: "elementi/:slug",
        },
        {
          element: <SedieFrontPage link={"sedeie-e-poltroncines"} />,
          path: "sedie/:slug",
        },
        {
          element: <TavoliFrontPage link={"tavoli-e-tavolinis"} />,
          path: "tavoli/:slug",
        },
        {
          element: <LettiFrontPage link={"letti-kids"} />,
          path: "lettikids/:slug",
        },
        {
          element: <LettiFrontPage2 link={"letti-sovrapposti-e-acastellos"} />,
          path: "lettiacastello/:slug",
        },
        {
          element: <InnerPage />,
          path: ":main_page/:product_name/:locale",
        },
        {
          element: <LettiInner />,
          path: "lettikids/:slug/:product_name",
        },
        {
          element: <SedieInner />,
          path: "sedie/:slug/:product_name",
        },
        {
          element: <TavoliInner />,
          path: "tavoli/:slug/:product_name",
        },
        {
          element: <LettiInner2 />,
          path: "lettiacastello/:slug/:product_name",
        },
        {
          element: <Cataloghi />,
          path: "/cataloghi",
        },
        {
          element: <Cataloghi11 />,
          path: "/scheda-prodotti",
        },
        {
          element: <Rivenditori />,
          path: "/rivenditori",
        },
        {
          element: <Contacts />,
          path: "/contatti",
        },
        {
          element: <News />,
          path: "/news",
        },
        {
          element: <NewsInner />,
          path: "/news/:slug",
        },
        {
          element: <Azienda />,
          path: "/azienda",
        },
        {
          element: <Cookies />,
          path: "/cookies",
        },
        {
          element: <Policy />,
          path: "/policy",
        },
        {
          element: <Certificazioni />,
          path: "/certificazioni",
        },
        {
          path: "*",
          element: <NotFoundComponent />, // Renders the NotFoundComponent on 404
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
