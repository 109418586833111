import { Link } from "react-router-dom";
import classes from "../../../css/FrontPage.module.css";
export default function SingleProduct(props) {
  const encodedUrl = encodeURIComponent(props.product_name.toLowerCase());
  return (
    <Link to={`./${encodedUrl}`} className={classes["img-link"]}>
      <div className="sec-12 padding--lr-0">
        <img
          className="firstImg"
          id="boxImg"
          src={`https://spagnoladmin.spagnolmobili.it${props.image}`}
          alt="armadi ad anta battente design"
        />
      </div>

      <div id="col-h" className="sec-12">
        <h3 className={classes["heading-01"]}>{props.productname}</h3>
      </div>
    </Link>
  );
}
