import React from "react";
import { useNavigate } from "react-router-dom";
const NotFoundComponent = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate("/"); // Redirect to home page on mount
  }, [navigate]);

  return (
    <div>
      <h1>404 - Page Not Found</h1>
      <p>The page you requested could not be found.</p>
    </div>
  );
};

export default NotFoundComponent;
