import "../../css/Header.css";
import { useContext, useState, useEffect } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

import { GiHamburgerMenu } from "react-icons/gi";
import useFetch from "../../hooks/useFetch";
import LanguageContext from "../../context/language-context";
import MobileMenu from "./MobileMenu";
export default function NewMobileHeader(props) {
  const languageCtx = useContext(LanguageContext);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const languages = ["en", "it", "es", "de", "fr"];

  const toggleSubMenu = () => {
    // event.preventDefault();
    setIsSubMenuVisible(!isSubMenuVisible);
  };
  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/headers?locale=${languageCtx.languageData}&populate[0]=Logo`
  );
  const [selectedLanguage, setSelectedLanguage] = useState("it"); // Default language

  useEffect(() => {
    // Retrieve the selected language from localStorage if available
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage && languages.includes(savedLanguage)) {
      setSelectedLanguage(savedLanguage);
      languageCtx.changeLanguage(savedLanguage); // Ensure context is updated as well
    }
  }, []);

  const changeLanguage = (lang) => {
    toggleSubMenu();

    languageCtx.changeLanguage(lang);
    setSelectedLanguage(lang);
    localStorage.setItem("language", lang); // Store the selected language in localStorage
  };

  const languageClicked = (lang, e) => {
    e.preventDefault();
    changeLanguage(lang);
  };
  if (loading) return <p>Loading...</p>;
  return (
    <>
      <div className="Mobileview">
        <nav className="navbar navbar-light mobile">
          <div className="container-fluid">
            <div className="navbar-brand">
              <a href="/">
                <img
                  src={`https://spagnoladmin.spagnolmobili.it${data.data[0].attributes.Logo.data.attributes.url}`}
                />
              </a>
            </div>
            <ul className="language-parent">
              <li>
                <a
                  className="current-language"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleSubMenu();
                  }}
                >
                  {selectedLanguage.toUpperCase()}
                  <IoMdArrowDropdown />
                </a>
                <ul
                  className={`language-sub ${
                    isSubMenuVisible ? "visible" : ""
                  }`}
                >
                  {languages
                    .filter((lang) => lang !== selectedLanguage)
                    .map((lang) => (
                      <li key={lang}>
                        <a
                          className={lang}
                          href="#"
                          onClick={(e) => languageClicked(lang, e)}
                        >
                          {lang.toUpperCase()}
                        </a>
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
            <div className="d-flex header__toggle">
              <GiHamburgerMenu onClick={props.showMobileMenu} />
            </div>
          </div>
        </nav>
        <MobileMenu
          isopen={props.isopen}
          showMobileProdotti={props.showMobileProdotti}
          ToggleMobileProdotti={props.ToggleMobileProdotti}
        />
      </div>
    </>
  );
}
