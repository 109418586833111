import Products from "./Products";
import useFetch from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import ApiContext from "../../../context/api-context";
import PageHeading from "../Front Pages/PageHeading";
import classes from "../../../css/FrontPage2.module.css";

export default function FrontPage2(props) {
  if (props.link) {
    localStorage.setItem("link", props.link);
  }

  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/${props.link}?populate[Product_name_and_Image][populate][0]=ProductImage`
  );
  const ctx = useContext(ApiContext);

  const { slug } = useParams();
  if (loading) return <p>Loading...</p>;
  const { data: details, meta } = data;

  // Find the details object with the matching slug
  const matchingDetails = details.find((item) => {
    return item.attributes.Page_Name === slug;
  });

  if (!matchingDetails) {
    return <p>No matching details found for the provided slug.</p>;
  }

  return (
    <div className={classes["main-content"]}>
      <PageHeading pageheading={matchingDetails.attributes.Page_Heading} />
      <Products
        products={matchingDetails.attributes.Product_name_and_Image}
        slug={matchingDetails.attributes.slug}
        link={props.link}
      />
    </div>
  );
}
