import "../../../css/cookies.css";
export default function Policy() {
  return (
    <div className="main-section privacybody">
      <h1>Informativa sulla privacy</h1>
      <p>Data di entrata in vigore: 2022-12-12</p>
      <p>Ultimo aggiornamento: 2023-06-08</p>
      <p>
        La presente Informativa sulla privacy spiega le politiche di
        SPAGNOLMOBILI sulla raccolta e l'uso delle informazioni che raccogliamo
        quando accedi a https://www.spagnolmobili.it/ (il "Servizio"). La
        presente Informativa sulla privacy descrive i tuoi diritti e in che
        maniera sei protetto dalle leggi sulla privacy.
      </p>
      <p>
        Utilizzando il nostro Servizio, acconsenti alla raccolta e all'utilizzo
        delle tue informazioni in conformità con la presente Informativa sulla
        privacy. Ti preghiamo di non accedere o utilizzare il nostro Servizio se
        non acconsenti alla raccolta e all'utilizzo delle informazioni come
        indicato nella presente Informativa sulla privacy. La presente
        Informativa sulla privacy è stata creata con l'aiuto del
        <a
          target="_blank"
          href="https://cookie-script.com/privacy-policy-generator"
        >
          Generatore di informative sulla privacy di CookieScript
        </a>
        .
      </p>
      <p>
        SPAGNOLMOBILI è autorizzata a modificare la presente Informativa sulla
        privacy in qualsiasi momento. Questo può avvenire senza preavviso.
      </p>
      <p>
        SPAGNOLMOBILI pubblicherà l'Informativa sulla privacy modificata sul
        sito web https://www.spagnolmobili.it/.
      </p>
      <h3>Raccolta e utilizzo delle tue informazioni personali</h3>
      <h4>Informazioni raccolte</h4>
      <p>
        Quando utilizzi il nostro Servizio, ti verrà richiesto di fornirci delle
        informazioni personali utilizzate per contattarti o identificarti.
        https://www.spagnolmobili.it/ raccoglie le seguenti informazioni:
      </p>
      <ul id="cookie-ul">
        <li>Dati di utilizzo</li>
      </ul>
      <p>I dati di utilizzo includono quanto segue:</p>
      <ul id="cookie-ul">
        <li>
          Indirizzo Protocollo Internet (IP) dei computer che accedono al sito
        </li>
        <li>Richieste di pagine web</li>
        <li>Pagine web di riferimento</li>
        <li>Browser utilizzato per accedere al sito</li>
        <li>Ora e data di accesso</li>
      </ul>
      <h4>Come raccogliamo le informazioni</h4>
      <p>
        https://www.spagnolmobili.it/ raccoglie e riceve informazioni da parte
        tua nelle seguenti modalità:
      </p>
      <ul id="cookie-ul">
        <li>
          Quando compili un modulo di registrazione o invii le tue informazioni
          personali in altro modo.
        </li>
      </ul>
      <p>
        Le tue informazioni saranno conservate per un massimo di 90 giorni dopo
        la chiusura del tuo account. Le tue informazioni possono essere
        conservate per periodi più lunghi per il reporting o per scopi di
        registrazione in conformità con le leggi applicabili. Le informazioni
        che non ti identificano personalmente possono essere conservate a tempo
        indeterminato.
      </p>
      <h4>Come usiamo le tue informazioni</h4>
      <p>
        https://www.spagnolmobili.it/ può utilizzare le tue informazioni per i
        seguenti scopi:
      </p>
      <ul id="cookie-ul">
        <li>
          <b>Fornire e mantenere il nostro Servizio,</b> così come monitorare
          l'utilizzo del nostro Servizio.
        </li>
        <li>
          <b>Per altri scopi.</b> SPAGNOLMOBILI userà le tue informazioni per
          l'analisi dei dati per identificare le tendenze di utilizzo o
          determinare l'efficacia delle nostre campagne di marketing, quando
          ragionevole. Useremo le tue informazioni per valutare e migliorare il
          nostro Servizio, i prodotti, i servizi e le attività di marketing.
        </li>
      </ul>
      <h4>Come condividiamo le tue informazioni</h4>
      <p>
        SPAGNOLMOBILI condividerà le tue informazioni, se del caso, nelle
        seguenti situazioni:
      </p>
      <ul id="cookie-ul">
        <li>
          <b>Con il tuo consenso.</b> SPAGNOLMOBILI condividerà le tue
          informazioni per qualsiasi scopo con il tuo esplicito consenso.
        </li>
      </ul>
      <h4>Condivisione con terze parti</h4>
      <p>
        Qualsiasi terza parte con cui condividiamo le tue informazioni deve
        rivelare lo scopo per cui intende utilizzare le tue informazioni. Deve
        conservare le tue informazioni solo per la durata rivelata al momento
        della richiesta o della ricezione di tali informazioni. Il terzo
        fornitore di servizi non deve raccogliere, vendere o utilizzare
        ulteriormente le tue informazioni personali se non nella misura
        necessaria per eseguire lo scopo specificato.
      </p>
      <p>
        Le tue informazioni possono essere condivise con una terza parte per
        ragioni che includono:
      </p>
      <ul id="cookie-ul">
        <li>
          <b>Informazioni statistiche.</b> Le tue informazioni potrebbero essere
          condivise con strumenti di analisi online al fine di tracciare e
          analizzare il traffico del sito web.
        </li>
      </ul>
      <p>
        Se scegli di fornire tali informazioni durante la registrazione o in
        altro modo, stai dando a SPAGNOLMOBILI il permesso di utilizzare,
        condividere e memorizzare tali informazioni in conformità con questa
        Informativa sulla privacy.
      </p>
      <p>
        Le tue informazioni possono essere divulgate per altri motivi, tra cui:
      </p>
      <ul id="cookie-ul">
        <li>
          Conformità con le normative, i regolamenti o le ingiunzioni
          applicabili.
        </li>
        <li>
          Risposta a reclami sul tuo uso del nostro Servizio violando diritti di
          terzi.
        </li>
        <li>
          Rispetto degli accordi stipulati con noi, compresa la presente
          Informativa sulla privacy.
        </li>
      </ul>
      <h4>Cookie</h4>
      <p>
        I cookie sono piccoli file di testo che vengono collocati sul tuo
        computer dai siti web che visiti. I siti web utilizzano i cookie per
        aiutare gli utenti a navigare in modo efficiente ed eseguire determinate
        funzioni. I cookie che sono necessari per il corretto funzionamento del
        sito web possono essere impostati senza il tuo permesso. Tutti gli altri
        cookie devono essere approvati prima di essere impostati nel browser.
      </p>
      <ul id="cookie-ul">
        <li>
          <b>Cookie strettamente necessari.</b> I cookie strettamente necessari
          consentono le funzionalità principali del sito web come l'accesso
          dell'utente e la gestione dell'account. Il sito web non può essere
          utilizzato correttamente senza i cookie strettamente necessari.
        </li>
        <li>
          <b>Cookie di performance.</b> I cookie di performance vengono
          utilizzati per vedere come i visitatori utilizzano il sito web, ad
          esempio con i cookie analitici. Questi cookie non possono essere
          utilizzati per identificare direttamente un determinato visitatore.
        </li>
        <li>
          <b>Cookie di targeting.</b> I cookie di targeting vengono utilizzati
          per identificare i visitatori tra diversi siti web, ad esempio partner
          di contenuti, reti di banner. Questi cookie possono essere utilizzati
          dalle aziende per creare un profilo degli interessi dei visitatori o
          mostrare annunci pertinenti su altri siti web.
        </li>
        <li>
          <b>Cookie di funzionalità.</b> I cookie di funzionalità vengono
          utilizzati per ricordare le informazioni del visitatore sul sito web,
          ad esempio lingua, fuso orario e contenuto avanzato.
        </li>
        <li>
          <b>Cookie non classificati.</b> I cookie non classificati sono cookie
          che non appartengono a nessun'altra categoria o che sono in fase di
          categorizzazione.
        </li>
      </ul>
      <div className="cookie-report-container">
        <p data-cookiereport="true">
          Puoi modificare il tuo consenso all'uso dei cookie qui sotto.
        </p>
      </div>
      <h4>Sicurezza</h4>
      <p>
        La sicurezza delle tue informazioni è importante per noi.
        https://www.spagnolmobili.it/ utilizza una serie di misure di sicurezza
        per prevenire l'uso improprio, la perdita o l'alterazione delle
        informazioni che ci hai fornito. Tuttavia, poiché non possiamo garantire
        la sicurezza delle informazioni che ci fornisci, stai eseguendo
        l'accesso al nostro servizio a tuo rischio e pericolo.
      </p>
      <p>
        SPAGNOLMOBILI non è responsabile delle prestazioni dei siti web gestiti
        da terzi o delle tue interazioni con essi. Quando abbandoni questo sito
        web, ti consigliamo di rivedere le pratiche in materia di privacy degli
        altri siti web con cui interagisci e determinare l'adeguatezza di tali
        pratiche.
      </p>
      <h4>Contatti</h4>
      <p>
        Per qualsiasi domanda, puoi contattarci attraverso i seguenti canali:
      </p>
      <p>Nome: SPAGNOL MOBILI s.r.l</p>
      <p>Indirizzo:Via Vidor 2, 31010 Mosnigo di Moriago (TV) - ITALY</p>
      <p>E-mail: privacy@spagnolmobili.it</p>
      <p>Sito web: https://www.spagnolmobili.it/</p>
      <p>Telefono: +39 0438 8911</p>
    </div>
  );
}
