import { useState } from "react";
import React from "react";
import ApiContext from "./api-context";
function ApiProvider(props) {
  const [apiData, setApi] = useState("");

  function changeApi(val) {
    setApi(val);
  }
  const routesInfo = [
    { name: "armadibattenti", link: "armadi-battentis" },
    { name: "armadiscorevolli", link: "armadi-scorevollis" },
    { name: "armadialibro", link: "armadi-a-libros" },

    { name: "letti", link: "lettis" },
    { name: "comòecomodini", link: "como-e-comodinis" },
    { name: "cabinearmadio", link: "cabine-armadios" },
    { name: "chisurescorevolli", link: "chisurescorevollis" },
    { name: "scomparsa", link: "scomparsas" },
    { name: "servizio", link: "servizios" },
    { name: "libreria_", link: "librerias" },
    { name: "contenitori", link: "contenitoris" },
    { name: "madie", link: "madies" },
    { name: "camrette-", link: "camrettes" },
    { name: "scrittoi", link: "scritois" },
    { name: "lettobaldacchino", link: "letto-baldacchinos" },
    { name: "lettovega", link: "letto-vegas" },
    { name: "kids", link: "como-e-comodini-kids" },
    { name: "boiserie_", link: "boiseries" },
    { name: "elementiinmetallo", link: "elementi-in-metallos" },
    { name: "elementi", link: "elementi-a-giornos" },
    { name: "sedie", link: "sedeie-e-poltroncines" },
    { name: "tavoli", link: "tavoli-e-tavolinis" },
    { name: "lettikids", link: "letti-kids" },
    { name: "lettiacastello", link: "letti-sovrapposti-e-acastellos" },
  ];

  return (
    <ApiContext.Provider value={routesInfo}>
      {props.children}
    </ApiContext.Provider>
  );
}

export default ApiProvider;
