import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { useContext } from "react";
import LanguageContext from "../../context/language-context";
export default function WorldInformation(props) {
  const languageCtx = useContext(LanguageContext);
  const translations = {
    en: {
      paragraph: "Dealers around the globe",
      agents: "Agents",
    },
    fr: {
      paragraph: "Concessionnaires dans le monde entier",
      agents: "Agents",
    },
    it: {
      paragraph: "Rivenditori in tutto il mondo",
      agents: "Agenti",
    },
    de: {
      paragraph: "Händler weltweit",
      agents: "Agenten",
    },
    es: {
      paragraph: "Distribuidores en todo el mundo",
      agents: "Agentes",
    },
  };

  return (
    <div className="chosenDealer">
      <h4 className="paragraph-3 align-center">
        {" "}
        {translations[languageCtx.languageData].paragraph} ({props.country})
      </h4>
      <div className="agent-info">
        <div className="agent">
          <p className="heading-3">
            {" "}
            {translations[languageCtx.languageData].agents}
          </p>
          <p id="dealerName">{props.name}</p>
        </div>
        <div className="email">
          <div className="emailtag">
            <a
              href={`mailto:${props.email}`}
              className="emailStore nvtk-link"
              data-url="source=email"
            >
              <FaEnvelope />
            </a>
          </div>
          <div className="emailaddress">
            <a
              className="emailStore nvtk-link"
              href={`mailto:${props.email}`}
              data-url="source=email"
            >
              {props.email}
            </a>
          </div>
        </div>
        <div className="phone-number">
          <a
            href={`tel:${props.phonenumber}`}
            className="phoneStore nvtk-link"
            data-url="source=phone"
          >
            <FaPhoneAlt />
          </a>
          <div className="number">
            <a
              className="emailStore nvtk-link"
              href={`tel:${props.phonenumber}`}
              data-url=""
            >
              {props.phonenumber}
            </a>
          </div>
        </div>
      </div>
      <button id="close" onClick={props.closeAgentHandler}>
        CLOSE
      </button>
    </div>
  );
}
