import { useContext } from "react";
import "../../css/Region.css";
import LanguageContext from "../../context/language-context";
export default function Agents(props) {
  const languageCtx = useContext(LanguageContext);

  const handleOptionChange = (event) => {
    props.handleOptionChange(event.target.value);
  };
  const translations = {
    en: {
      title: "PROVINCE",
      description: "SEARCH",
    },
    fr: {
      title: "PROVINCE",
      description: "RECHERCHE",
    },

    it: {
      title: "PROVINCIA",
      description: "RICERCA",
    },
    de: {
      title: "PROVINZ",
      description: "SUCHEN",
    },
    es: {
      title: "PROVINCIA",
      description: "BUSCAR",
    },
  };
  return (
    <div className="regions">
      <span className="custom-dropdown custom-dropdown--white custom-dropdown--large">
        <select
          className="custom-dropdown__select custom-dropdown__select--white"
          onChange={handleOptionChange}
        >
          <option value="" className="trprovince">
            {translations[languageCtx.languageData].title}
          </option>
          {props.agents.map((item, index) => (
            <option value={item.value} key={index}>
              {item.region}
            </option>
          ))}
        </select>
      </span>
      <a id="contact-btn" className="fom">
        <button
          id="close"
          className="searchbtn"
          onClick={props.searchAgentHandler}
        >
          {translations[languageCtx.languageData].description}
        </button>
      </a>
    </div>
  );
}
