import { BlocksRenderer } from "@strapi/blocks-react-renderer";
export default function PageHeading(props) {
  const content = props.content;
  return (
    <div
      className="letti-head"
      style={{
        backgroundImage: `url(https://spagnoladmin.spagnolmobili.it${props.image})`,
      }}
    >
      <div>
        <BlocksRenderer content={content} />
      </div>
    </div>
  );
}
