import SingleProduct from "./Singleproduct";
import classes from "../../../css/FrontPage.module.css";
export default function Products(props) {
  return (
    <div className={classes["product-grid"]}>
      {props.products.map((item, index) => {
        let productDescription = item.product_name;
        if (productDescription.includes("-")) {
          productDescription = productDescription.split("-").join(" ");
        }
        if (item.Published == false) {
          return <></>;
        }
        return (
          <SingleProduct
            image={item.ProductImage.data.attributes.url}
            productname={productDescription}
            product_name={item.product_name}
            page_name={props.page_name}
            key={index}
            locale={props.locale}
            link={props.link}
          />
        );
      })}
    </div>
  );
}
