export default function News(props) {
  return (
    <div className="news-container">
      <div className="base-80">
        <a
          href="/news"
          id="thirdContent"
          className="index3 underline-hover-effect"
        >
          {props.news}
        </a>
        <div className="clearfix" />
      </div>
      <div className="col-lg-6 offset-lg-1 sec-12 offset-0 wd-40">
        {/* <a href="/news" className="noGallery"> */}
        <img
          id="thirdImg"
          src={`https://spagnoladmin.spagnolmobili.it${props.newsimage}`}
        />
        {/* </a> */}
      </div>
    </div>
  );
}
