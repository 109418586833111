import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import LanguageContext from "../../context/language-context";
import { IoMdArrowDropdown } from "react-icons/io";

const languages = ["en", "it", "es", "de", "fr"];

export default function LanguageMenu() {
  const { slug } = useParams();
  const languageCtx = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState("it"); // Default language
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const toggleSubMenu = () => {
    // event.preventDefault();
    setIsSubMenuVisible(!isSubMenuVisible);
  };
  useEffect(() => {
    // Retrieve the selected language from localStorage if available
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage && languages.includes(savedLanguage)) {
      setSelectedLanguage(savedLanguage);
      languageCtx.changeLanguage(savedLanguage); // Ensure context is updated as well
    }
  }, []);

  const changeLanguage = (lang) => {
    languageCtx.changeLanguage(lang);
    setSelectedLanguage(lang);
    localStorage.setItem("language", lang); // Store the selected language in localStorage
  };

  const languageClicked = (lang, e) => {
    toggleSubMenu();

    e.preventDefault();
    changeLanguage(lang);
  };

  return (
    <ul className="language-parent">
      <li>
        <a
          className="current-language"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            toggleSubMenu();
          }}
        >
          {selectedLanguage.toUpperCase()}
          <IoMdArrowDropdown />
        </a>
        <ul className={`language-sub ${isSubMenuVisible ? "visible" : ""}`}>
          {languages
            .filter((lang) => lang !== selectedLanguage)
            .map((lang) => (
              <li key={lang}>
                <a
                  className={lang}
                  href="#"
                  onClick={(e) => languageClicked(lang, e)}
                >
                  {lang.toUpperCase()}
                </a>
              </li>
            ))}
        </ul>
      </li>
    </ul>
  );
}
