export default function SingleCataloghi(props) {
  return (
    <li className="item">
      <a
        href={`https://spagnoladmin.spagnolmobili.it${props.pdf}`}
        target="_blank"
        className="catalogh-link"
      >
        <div className="img">
          <img
            className="firstimg"
            src={`https://spagnoladmin.spagnolmobili.it${props.image}`}
            alt="cover pdf"
          />
        </div>
        <span className="underlined_element">Download</span>
      </a>
    </li>
  );
}
