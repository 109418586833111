import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import ProductList from "./ProductList";
import useFetch from "../../../hooks/useFetch";
import { useParams } from "react-router-dom";
import "../../../css/InnerPage.css";
import { useContext, useEffect } from "react";
import ApiContext from "../../../context/api-context";
import LanguageContext from "../../../context/language-context";
import { NavLink } from "react-router-dom";
import Popup from "../../UI/Popup";

export default function InnerPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top-left corner of the page
  }, []);
  const { main_page, product_name, locale } = useParams();
  const ctx = useContext(ApiContext);
  const languageCtx = useContext(LanguageContext);
  let links;
  if (props.link) {
    // Use props.link if it is available
    links = props.link;
    console.log(props.link);
  } else {
    ctx.forEach((element) => {
      if (element.name === main_page) links = element.link;
    });
  }
  const CustomPropsBreadcrumb = ({ someProp }) => <span>/ {someProp}</span>;
  const routes = [
    {
      path: "product_name/:locale/",
      breadcrumb: CustomPropsBreadcrumb,
      props: { someProp: pagename },
    },
  ];
  const breadcrumbs = useBreadcrumbs(routes);
  const { loading, error, data } = useFetch(
    `https://spagnoladmin.spagnolmobili.it/api/${links}?locale=${languageCtx.languageData}&populate[0]=Product_name_and_Image&populate[1]=Product_name_and_Image.Inner_page_details.bigimage&populate[2]=Product_name_and_Image.Inner_page_details.mediumimage&populate[3]=Product_name_and_Image.Inner_page_details.smallimage&populate[4]=Product_name_and_Image.Popup.Icon&populate[5]=Product_name_and_Image.Popup.Pdf`
  );
  if (loading) return <p>Loading...</p>;

  const { data: details, meta } = data;

  const matchingDetails = details.find((item) => {
    return item.attributes.Product_name_and_Image.find((items) => {
      return items.product_name.toLowerCase() === locale.toLowerCase();
    });
  });
  console.log(matchingDetails);
  var selectedProducts;
  var pagename;

  if (matchingDetails) {
    if (Array.isArray(matchingDetails.attributes.Product_name_and_Image)) {
      selectedProducts = matchingDetails.attributes.Product_name_and_Image.find(
        (item) => {
          pagename = item.product_name;
          return item.product_name.toLowerCase() === locale.toLowerCase();
        }
      );
    } else {
      selectedProducts = matchingDetails.attributes.Product_name_and_Image;
    }
  }

  if (!selectedProducts) {
    return <p>Product not found</p>;
  }
  const uniqueBreadcrumbs = breadcrumbs;

  breadcrumbs.forEach(({ match, breadcrumb }, index) => {
    const isDuplicate =
      index > 0 &&
      breadcrumb.props.children ===
        breadcrumbs[index - 1].breadcrumb.props.children;

    if (isDuplicate) {
      uniqueBreadcrumbs.splice(index - 1, 1);
    }
  });
  return (
    <div className="main-inner">
      <div className="breadcrumb-wrapper">
        {uniqueBreadcrumbs.map(({ match, breadcrumb }, index) => {
          if (uniqueBreadcrumbs.length == 4) {
            if (index == 1) {
              return null;
            }
          }
          if (breadcrumb.props.children === "Armadibattenti") {
            return null;
          }
          const isLastBreadcrumb = index === breadcrumbs.length - 1;
          const isSecondToLastBreadcrumb = index === breadcrumbs.length - 2;
          return (
            <React.Fragment key={match.pathname}>
              <NavLink to={match.pathname}>
                {!isSecondToLastBreadcrumb
                  ? decodeURIComponent(breadcrumb.props.children)
                  : matchingDetails.attributes.Page_Name}
              </NavLink>
              {!isLastBreadcrumb && " / "}
              {/* Render a slash after each breadcrumb except the last one */}
            </React.Fragment>
          );
        })}
      </div>
      <ProductList products={selectedProducts.Inner_page_details} />
      <Popup products={selectedProducts.Popup} />
    </div>
  );
}
