import SingleCataloghi from "./SingleCatalog";
export default function CataloghList(props) {
  return (
    <div className="catalogues">
      <ul className="catalogues-item">
        {props.products.map((item, index) => {
          return (
            <SingleCataloghi
              image={item.catalogimage.data.attributes.url}
              pdf={item.catalogpdf.data.attributes.url}
              key={index}
            />
          );
        })}
      </ul>
    </div>
  );
}
