import React, { useContext } from "react";

import "../../css/rivenditori.css";
import LanguageContext from "../../context/language-context";
export default function ItaliaText() {
  const languageCtx = useContext(LanguageContext);

  // Object containing translations for different languages
  const translations = {
    en: {
      title: "ITALY",
      description: "Find agents near you",
    },
    fr: {
      title: "ITALIE",
      description: "Trouvez des agents près de chez vous",
    },
    it: {
      title: "ITALIA",
      description: "Trova agenti vicino a te",
    },
    de: {
      title: "ITALIEN",
      description: "Finden Sie Agenten in Ihrer Nähe",
    },
    es: {
      title: "ITALIA",
      description: "Encuentre agentes cerca de usted",
    },
  };
  return (
    <div className="align-center">
      <h2 className="italia">{translations[languageCtx.languageData].title}</h2>
      <p className="agents">
        {translations[languageCtx.languageData].description}
      </p>
    </div>
  );
}
